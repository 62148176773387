import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../apiServices/category/category.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';

@Component({
  selector: 'app-dashboard-active-challenges',
  templateUrl: './dashboard-active-challenges.component.html',
  styleUrls: ['./dashboard-active-challenges.component.scss']
})
export class DashboardActiveChallengesComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  challengesList: any = [];
  show_to: any = "1";
  status: any = '1';
  memberType: any = '1';
  dare_filter: any = '1';
  apiCalled: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private categoryService: CategoryService,
    private challengeService: ChallengeService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": "1",
      "dare_filter": this.dare_filter || "2",
      "page_size": "2"
    }
    this.challengeService.getActiveChallenges(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengesList = res.myChallengeList;
        console.log('res----', this.challengesList)
      } else if (res.responseCode == 402) {
        // this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
