import { Component, OnInit, OnDestroy, ViewChild, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../../apiServices/chat/chat.service';
import { ToastrService } from 'ngx-toastr';
import { ScrollToBottomDirective } from '../../_directives';
import { LocalStorageService } from '../helper/localStorage.service';
import * as $ from 'jquery';
import { Event } from '../../_models/event';
import { Room } from '../../_models/room';
import { RoomMessage } from '../../_models/roomMessage';
import { Message } from '../../_models/message';
import { MessageWithPagination } from '../../_models/messageWithPagination';
// import { Message } from '../../apiServices/model/message';
import { ChatUtil } from '../../_utils/chat';
import { environment } from '../../../environments/environment';
import { UserService } from '../../apiServices/user/user.service';


@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {
  windowScrolled: boolean;
  @ViewChild(ScrollToBottomDirective, { static: true })
  scroll: ScrollToBottomDirective;

  chatForm: any = { message: '' };
  loading = false;
  showError = false;
  roomList: any = [];
  messages: any = [];
  pagination: any;
  user: any;
  activeRoom: any;
  socketConnected = false;
  socket: any;
  fileUrl: string;
  otherUserName: string = '';
  userId: string;
  userName: string;
  percent: number = 0;
  chatListLoading: boolean = false;
  isScroll: boolean = true;
  searchText: string;
  searchParticipants: string;
  showGroupDetails: boolean = false;
  showEmoji: boolean = false;
  file: any;
  firstRequestOfThisGroup: boolean = false;
  users: any;
  filtered_users: any;
  groupUpdating: boolean = false;
  showChatBox: boolean = false;
  showGroupChats: any;
  index: any;
  filteredRoom: any = [];
  memberList: any = [];
  matchRoom: any;
  seachUser: any;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));

  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 1;
  paramsValue: any;

  constructor(
    private socketService: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    public util: ChatUtil,
    private cdref: ChangeDetectorRef,
    private userService: UserService
  ) {
    this.paramsValue = this.route.params;
    this.fileUrl = environment.fileUrl;
    this.user = this.authToken;
    this.userId = this.user.user_id;
    this.userName = this.user.user_name;
    this.socket = this.socketService.getSocket();
    console.log(this.socket);
  }

  ngOnInit() {
    this.user = this.authToken;
    this.userId = this.user.user_id;
    this.userName = this.user.user_name;
    this.socket = this.socketService.getSocket();
    this.initIoConnection();
    this.socketService.init({ userId: this.userId, userName: this.userName });
    //this.getUserList();
  }
  ngOnDestroy() {
    this.socketService.disconnect();
  }

  // getUserList() {
  //   this.userService.userList().subscribe(objS => {
  //     this.users = objS.status == 200 ? objS.data : [];
  //   }, (err) => {
  //   });
  // }

  getMemberList(dareIds) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "dareIds": dareIds
    }
    // this.dareService.getDareDetailsUsingPlayId(reqObj).subscribe(res => {
    this.userService.getMembers(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.memberList = res.memberList;
        // console.log('check----', this.memberList)
        // this.myBetDetails = JSON.parse(localStorage.getItem('dare'));
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getSenderName(senderId) {
    // console.log('check---', senderId)
    if (this.memberList && this.memberList.length != 0) {
      return this.memberList.find(x => x.user_id === senderId).user_name;
    }
    // for (let i = 0; i < this.memberList.length; i++) {
    //   console.log('this=', Number(this.memberList[i].user_id), Number(senderId))
    //   if (Number(this.memberList[i].user_id) == Number(senderId)) {
    //     console.log('getSenderName===', this.memberList[i].user_name)
    //     return this.memberList[i].user_name
    //   } else {
    //     return ''
    //   }
    // }
  }
  track(value: number): void {
    // console.log('call up ====', value)
    if (value == 0 && this.pagination.page < this.pagination.pages) {
      this.firstRequestOfThisGroup = false;
      this.getChats(this.activeRoom);
    }
    if (value > 90) this.isScroll = true;
  }
  onScrollUp(ev) {
    console.log('scrolled up!', ev);
    const start = this.sum;
    this.sum += 20;
    this.firstRequestOfThisGroup = false;
    this.getChats(this.activeRoom);
  }
  contentChanged() {
    this.cdref.detectChanges();
  }
  onScrollDown() {
    console.log('scrolled down!!');
  }
  private initIoConnection(): void {
    // console.log('call=====', this.socket)
    if (this.socket && this.socket.connected) {
      this.socketConnected = true;
    } else {
      this.socketService.initSocket();
    }

    // this.getRoomList();
    this.socketService.rooms().subscribe((room: Room) => {
      console.log('====room', room)
      this.roomList = room;
      // var dareIds = this.roomList.filter(val => val.dareId).map((val) => val.dareId)
      // if (dareIds && dareIds.length != 0) {
      //   this.getMemberList(dareIds);
      // }
      // console.log(room);
    });

    this.socketService.newGroup().subscribe((room: Room) => {
      this.roomList.unshift(room);
      console.log("new group", room);
    });

    this.socketService.updatedGroup().subscribe((room: Room) => {
      this.updatedGroup(room);
      console.log("updated group", room);
    });

    this.socketService.saveMessage().subscribe((message: RoomMessage) => {
      console.log('saveMessage', message);
      if (this.activeRoom) {
        this.activeRoom.lastMessage = message.lastMessage;
        this.messages.push(message.lastMessage);
        this.filteredRoom = [];
        this.matchRoom = "";
        this.roomList.forEach((val) => {
          // console.log('-----val-----', val)
          if (val._id != this.activeRoom._id) {
            this.filteredRoom.push(val);
          } else {
            this.matchRoom = val;
          }
        })
        if (this.matchRoom) {
          this.filteredRoom.unshift(this.matchRoom);
        }
        // console.log('check data---', this.filteredRoom)
        this.roomList = this.filteredRoom;
        this.localStorageService.saveNewMessage(message.lastMessage);
        this.contentChanged();
      }
    });

    this.socketService.saveImageMessage().subscribe((message: RoomMessage) => {
      console.log('saveImageMessage======socket line 219', message);
      if (message && message['docs']) {
        message = message['docs']
      }
      this.saveImageMessage(message);
    });

    this.socketService.receiveMessage().subscribe((message: RoomMessage) => {
      console.log('receiveMessage line 224', message);
      if (message && message['docs']) {
        message = message['docs']
      }
      this.receiveMessage(message);
    });

    //@ delivered message on user if user online
    this.socketService.deliveredMessage().subscribe((message: Message) => {
      console.log('deliveredMessage line 230===', JSON.stringify(message));
      if (message && message['docs']) {
        message = message['docs']
      }
      this.updateMessage(message);
    });

    this.socketService.onEvent(Event.CONNECT).subscribe(() => {
      console.log('connected');
      this.socketConnected = true;
      this.init();
    });

    this.socketService.chats().subscribe((message: MessageWithPagination) => {
      console.log('chats ==', message);
      var { docs, limit, page, pages, total } = message;
      if (this.messages && this.messages.length && this.firstRequestOfThisGroup == false) {
        this.isScroll = false;
        this.messages = [...docs, ...this.messages];
      } else {
        this.isScroll = true;
        this.messages = docs;
      }
      this.contentChanged();
      this.pagination = { limit, page, pages, total };
      this.localStorageService.saveChatsWithPagination(this.messages, this.pagination, this.activeRoom._id);
      this.chatListLoading = false;

    });

    this.socketService.UnDeliverMessagesSent().subscribe((message: RoomMessage) => {
      //console.log('UnDeliverMessagesSent',message);;
    });

    //@ pending message delivered to user
    this.socketService.messageDelivered().subscribe((message: RoomMessage) => {
      if (message && message['docs']) {
        message = message['docs']
      }
      this.updateMessage(message);
      console.log('messageDelivered', message);;
    });

    this.socketService.messageRead().subscribe((message: Message) => {
      console.log('messageRead line 280', message);
      if (message && message['docs']) {
        message = message['docs']
      }
      this.messageRead(message);
    });

    this.socketService.onEvent(Event.DISCONNECT).subscribe(() => {
      console.log('disconnected');
      this.socketConnected = false;
    });

  }


  public init(): void {
    console.log("init");
    console.log(this.socketService.getSocket())
    this.socketService.init({ userId: this.userId, userName: this.userName });
    this.getRoomList();
  }

  public getRoomList(): void {
    console.log('call get rooms', this.userId)
    this.socketService.getRoomList(this.userId).subscribe(res => {
      if (res.status == 200) {
        this.loading = this.showError = false;
        this.roomList = res.data;
        // var dareIds = this.roomList.filter(val => val.dareId).map((val) => val.dareId)
        // if (dareIds && dareIds.length != 0) {
        //   this.getMemberList(dareIds);
        // }
        if (this.paramsValue._value.pid) {
          for (let i = 0; i < this.roomList.length; i++) {
            if (this.roomList[i].dareId == this.paramsValue._value.pid) {
              console.log('get room details==', this.roomList[i])
              this.selectRoom(this.roomList[i], i);
            }
          }
        }
        // console.log('res--==--dare---===----', this.roomList)
      }
    }, (err) => {
      console.log(err);
    });
    // this.socketService.roomList({ userId: this.userId });
  }

  public selectRoom(room, i): void {
    if (room.unreadMessage) {
      this.readMessage({ userId: this.userId, roomId: room._id });
    }
    this.index = i;
    this.messages = [];
    this.pagination = {};
    this.activeRoom = room;
    this.activeRoom.groupMember = this.util.isGroupMember(this.activeRoom.participants, this.userId)
    console.log('======>>>>', this.activeRoom.participants, this.activeRoom.groupMember, this.userId)
    this.activeRoom.unreadMessage = 0;
    this.isScroll = true;
    var roomChats = this.localStorageService.getChatsWithPagination(room._id);
    this.messages = roomChats.chats;
    this.pagination = roomChats.pagination;
    this.chatListLoading = false;
    this.firstRequestOfThisGroup = true;
    console.log(roomChats)
    //if(roomChats && roomChats.callApi){
    this.getChats(this.activeRoom);
    //}else{
    this.contentChanged();
    //}
  }

  getChats(room) {
    console.log('call----', room)
    this.socket = this.socketService.getSocket();
    if (this.socket.disconnected) {
      this.init();
      return;
    }

    if (!this.socketConnected) {
      this.chatListLoading = false;
      return;
    }

    var chatListReqObj = { userId: this.userId, roomId: room._id };
    if (this.messages && this.messages.length && this.firstRequestOfThisGroup == false) {
      chatListReqObj['lastMessageTimeStamp'] = this.messages[0].timeStamp;
    }
    if (this.chatListLoading == false) {
      this.chatListLoading = true;
      console.log("call chat list api");
      this.socketService.chatList(chatListReqObj);
    }
  }

  readMessage(data: any) {
    this.socketService.readMessage(data);
  }

  public sendMessage(): void {
    console.log('this.userId===', this.userId, this.activeRoom)
    this.socket = this.socketService.getSocket();
    if (this.socket.disconnected) {
      this.init();
      return;
    }
    this.showEmoji = false;
    // if (!this.activeRoom.groupMember) {
    //   this.toastr.info("You are not member of this group.");
    //   return;
    // }
    if (!this.socketConnected) {
      this.toastr.info("Please connect with internet");
      return;
    }

    if (this.chatForm.message && this.activeRoom) {
      var lastMessage = {
        senderId: this.userId,
        type: 'group',
        message: this.chatForm.message,
        roomId: this.activeRoom._id,
        dareId: this.activeRoom.dareId,
        timeStamp: new Date()
      };
      console.log('===check==', lastMessage)
      this.socketService.sendText(lastMessage);
      this.chatForm.message = "";
    }
  }

  // logout() {
  //   this.authService.logout();
  //   this.router.navigate(['/login']);
  // }

  getUserName(senderId: string) {
    return this.util.getUserName(senderId, this.activeRoom);
  }
  getTick(msg: any) {
    return this.util.getTick(msg);
  }

  getFileUrl(msg) {
    // console.log('msg====', msg)
    if (msg.uploading) return msg.mediaUrl;
    else return this.fileUrl + '/' + msg.mediaUrl;
    // else return "../../../../assets/images/loader.gif";
  }

  selectFile(files) {
    var filetype = this.util.catchFile(files);
    if (filetype == "image") {
      this.sendImage(files);
    } else {
      this.toastr.error("Invalid file. Only image allowed.");
    }
  }

  receiveMessage(message) {
    if (this.activeRoom && this.activeRoom._id == message.lastMessage.roomId) {
      this.messages.push(message.lastMessage);
      this.contentChanged();
      this.activeRoom.lastMessage = message.lastMessage;
      this.readMessage({
        userId: this.userId,
        roomId: message.lastMessage.roomId,
        messagesId: [message.lastMessage._id]
      })
      this.filteredRoom = [];
      this.matchRoom = "";
      this.roomList.forEach((val) => {
        if (val._id != this.activeRoom._id) {
          this.filteredRoom.push(val);
        } else {
          this.matchRoom = val;
        }
      })
      if (this.matchRoom) {
        this.filteredRoom.unshift(this.matchRoom);
      }
      this.roomList = this.filteredRoom;
      this.localStorageService.updateRoomChats(this.messages, this.activeRoom._id);
    } else {
      this.matchRoom = "";
      this.filteredRoom = [];

      for (var i = 0; i < this.roomList.length; i++) {
        if (this.roomList[i]._id == message.lastMessage.roomId) {
          this.roomList[i].unreadMessage = this.roomList[i].unreadMessage + 1;
          this.roomList[i].lastMessage = message.lastMessage;
          this.matchRoom = this.roomList[i];
          break;
        }
      }

      this.roomList.forEach((val) => {
        if (val._id != this.matchRoom._id) {
          this.filteredRoom.push(val);
        }
      })

      if (this.matchRoom) {
        this.filteredRoom.unshift(this.matchRoom);
      }
      this.roomList = this.filteredRoom;
      this.localStorageService.saveNewMessage(message.lastMessage);
    }
  }

  updateMessage(message) {
    if (this.activeRoom && this.activeRoom._id == message.lastMessage.roomId) {
      for (var i = this.messages.length - 1; i >= 0; i--) {
        if (this.messages[i]._id == message.lastMessage._id) {
          this.messages[i] = message.lastMessage;
          this.contentChanged();
          break;
        }
      }
      this.localStorageService.updateRoomChats(this.messages, this.activeRoom._id);
    }
  }


  saveImageMessage(message) {
    console.log('message===line 493', JSON.stringify(message))
    if (message && message['docs']) {
      message = message['docs']
    }
    if (this.activeRoom && this.activeRoom._id == message.lastMessage.roomId) {
      for (var i = this.messages.length - 1; i >= 0; i--) {
        if (this.messages[i].id == message.lastMessage.id) {
          this.messages[i] = message.lastMessage;
          this.localStorageService.saveNewMessage(message.lastMessage);
          this.contentChanged();
          break;
        }
      }
    }
  }

  messageRead(msgs) {
    if (!this.activeRoom) return;
    for (var i = 0; i < msgs.length; i++) {
      if (msgs[i].roomId == this.activeRoom._id) {
        for (var j = this.messages.length - 1; j >= 0; j--) {
          if (this.messages[j]._id == msgs[i]._id) {
            this.messages[j] = msgs[i];
            this.contentChanged();
            break;
          }
        }
      }
    }
    this.localStorageService.updateRoomChats(this.messages, this.activeRoom._id);
  }

  updatedGroup(updated_room) {
    var isNewGroup = true;
    for (let room of this.roomList) {
      if (room._id == updated_room._id) {
        room.participants = updated_room.participants;
        room.groupAdmins = updated_room.groupAdmins;
        room.groupName = updated_room.groupName;
        room.groupAvatar = updated_room.groupAvatar;
        room.groupMember = this.util.isGroupMember(room.participants, this.userId);
        isNewGroup = false;
        //this.activeRoom = room;
        this.groupUpdating = false;
        //this.ngxSmartModalService.close('myModal');
        //this.ngxSmartModalService.close('exitGroupModal');
        break;
      }
    }
    if (isNewGroup) {
      this.roomList.unshift(updated_room);
    }
  }

  getLastMessageOwnerName(room) {
    return this.util.getLastMessageOwnerName(room, this.userId);
  }

  getLastMessage(room) {
    return this.util.getLastMessage(room, this.userId);
  }

  sendImage(files) {
    this.socket = this.socketService.getSocket();
    if (this.socket.disconnected) {
      this.init();
      return;
    }
    console.log('this.activeRoom----', this.activeRoom)
    if (!this.activeRoom.groupMember) {
      this.toastr.info("You are not member of this group.");
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.file = reader.result;

      var req_obj = {
        type: 'group',
        roomId: this.activeRoom._id,
        dareId: this.activeRoom.dareId,
        senderId: this.userId,
        timeStamp: new Date(),
        file: files[0],
        mediaUrl: this.file,
        id: new Date().getTime(),
        messageType: "media",
        uploading: true,
        sender_detail: {
          name: this.userName
        }
      };
      this.messages.push(req_obj);
      this.activeRoom.lastMessage = req_obj;
      this.filteredRoom = [];
      this.matchRoom = "";
      this.roomList.forEach((val) => {
        if (val._id != this.activeRoom._id) {
          this.filteredRoom.push(val);
        } else {
          this.matchRoom = val;
        }
      })

      if (this.matchRoom) {
        this.filteredRoom.unshift(this.matchRoom);
      }
      this.roomList = this.filteredRoom;
      this.contentChanged();
      this.socketService.uploadImage(req_obj).subscribe(objS => { }, (objE) => { });
    }
  }

  exitGroup() {
    this.socket = this.socketService.getSocket();
    if (this.socket.disconnected) {
      this.init();
      return;
    }

    if (!this.activeRoom || !this.userId) return;
    var exit_group_req = { userId: this.userId, roomId: this.activeRoom._id };
    this.groupUpdating = false;
    this.socketService.exitGroup(exit_group_req);
  }

  cancel() {
    //this.ngxSmartModalService.close('exitGroupModal');
  }

  exitGroupConfirmation() {
    //this.ngxSmartModalService.open('exitGroupModal');
  }

  addEmoji(event) {
    this.chatForm.message = `${this.chatForm.message}${event.emoji.native}`;
    // this.ngxSmartModalService.close('exitGroupModal');
  }

  openAddParticipant() {
    var participants = this.activeRoom.participants.map((val) => val._id);
    this.filtered_users = this.users.filter((val) => {
      return participants.indexOf(val._id) == -1;
    })
    //this.ngxSmartModalService.open('myModal');
    this.filtered_users.map((v) => v['checked'] = false);
    this.seachUser = "";
  }

  addParticipant() {
    this.socket = this.socketService.getSocket();
    if (this.socket.disconnected) {
      this.init();
      return;
    }
    var participants = [];
    this.filtered_users.forEach((u) => {
      if (u.checked) participants.push(u._id);
    });
    var add_participant_req = {
      userId: this.userId,
      roomId: this.activeRoom._id,
      participants
    };
    if (participants.length == 0) {
      this.toastr.info("Please select at list one participant");
      return;
    }
    this.groupUpdating = false;
    //this.ngxSmartModalService.close('myModal');
    this.socketService.addParticipants(add_participant_req);
  }

}
