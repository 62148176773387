import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  mobile: any = "";
  myForm: any = {};
  loading: boolean = false;
  showError: boolean = false;
  count: any;
  isShowHide: boolean = true;
  isLogin: boolean = false;
  isSignup: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {

  }


  getmobilenumber() {
    if (this.mobile) {
      var reqObj = {
        "mobile_number": this.mobile
      }
      this.userService.sendSms(reqObj).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.mobile = "";
          this.toastr.success(res.successMessage);
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.toastr.error('Mobile number is required.');
    }
  }

}
