import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../apiServices/notification/notification.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import { updateHeaderService } from '../../_service'
import { AuthServiceService } from '../../auth-service.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  apiCalled: boolean = false;
  profileImage: any = '../../assets/images/user_avatar_thumbnail.png';
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/uploads/';
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  isShow: any = false;
  loading: boolean = false;
  isCheck: boolean = false;
  showError: boolean = false;
  submitted: boolean = false;
  notificationList: any = [];
  notificationsSetting: any = {};
  date_now: any;
  date_future: any;
  constructor(
    private notificationService: NotificationService,
    private authService: AuthServiceService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private header: updateHeaderService
  ) { }

  ngOnInit() {
    this.header.$isCalledService
      .subscribe((data) => {
        console.log('print top nav bar--', data)
        if (data) {
          this.getNotificationCounts();
        }
      })
    // this.authToken.userImage = this.authToken.image ?  this.imageUrl + this.authToken.image : this.profileImage
    this.authToken.userImage = this.authToken.image ? this.authToken.image : this.profileImage;
    this.getNotificationCounts();
  }

  showToggale() {
    this.isShow = !this.isShow;
    this.getNotificationList();
  }

  gettimeDiff(createAt, timestamp) {
    this.date_future = new Date(timestamp);
    this.date_now = new Date(createAt);
    var seconds = Math.floor((this.date_future - (this.date_now)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    return days > 0 ? `${days} days` : hours > 0 ? `${hours} hours` : minutes > 0 ? `${minutes} minutes` : seconds > 0 ? `${seconds} seconds` : '';
  }

  getNotificationList() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": 1,
      "page_size": 5
    }
    this.notificationService.getNotificationList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.notificationList = (res && res.getNotificationList) ? res.getNotificationList : [];
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getNotificationCounts() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.notificationService.getNotificationCount(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.notificationsSetting = res;
        this.apiCalled = true;
        // console.log('api data----', this.notificationsSetting)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
      if (err.status == 504) {
        this.toastr.error('Request Timeout.');
      }
    });
  }
  logout() {
    this.authService.logout();
  }

}
