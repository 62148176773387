import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // public opened: boolean = false;
  paramsDetails: any;
  constructor(private route: ActivatedRoute, private router: Router) {
    this.paramsDetails = this.route.params;
  }

  ngOnInit() {
    $(".sideToggle, .closeSideToggle").click(function () {
      $(".side-nav").toggleClass("sidebarClose");
      $(".main-content").toggleClass("main-content-slide");
    });
  }
  // toggleSidebar() {
  //   this.opened = !this.opened
  // }

  ngOnChanges(type) {
    // console.log('this.router.url===', this.router.url)
    if ((this.router.url == "/dare/" + this.paramsDetails.value.categoryId + "/" + this.paramsDetails.value.dareType) && (type == 'myDares')) {
      return "active"
    } else if ((this.router.url == "/view_dare_details/" + this.paramsDetails.value.pid) && (type == 'myDares')) {
      return "active"
    } else if ((this.router.url == "/editDare/" + this.paramsDetails.value.pid) && (type == 'myDares')) {
      return "active"
    } else if ((this.router.url == "/add_new_dare") && (type == 'myDares')) {
      return "active"
    } else if ((this.router.url == "/dare_detail/" + this.paramsDetails.value.pid) && (type == 'allDares')) {
      return "active"
    } else if ((this.router.url == "/" + this.paramsDetails.value.categoryId + "/challenge_won") && (type == 'all_challenges_won_lost_requests')) {
      return "active"
    } else if ((this.router.url == "/" + this.paramsDetails.value.categoryId + "/challenge_loss") && (type == 'all_challenges_won_lost_requests')) {
      return "active"
    } else if ((this.router.url == "/" + this.paramsDetails.value.categoryId + "/dare_request") && (type == 'all_requests')) {
      return "active"
    } else if ((this.router.url == "/" + this.paramsDetails.value.categoryId + "/challenge_request") && (type == 'all_requests')) {
      return "active"
    } else if ((this.router.url == "/" + this.paramsDetails.value.categoryId + "/challenges") && (type == 'challenges')) {
      return "active"
    } else if ((this.router.url == "/view_challenge_details/" + this.paramsDetails.value.pid) && (type == 'challenges')) {
      return "active"
    } else if ((this.router.url == "/" + this.paramsDetails.value.categoryId + "/" + this.paramsDetails.value.type + "/earning") && (type == 'dare_challenge_earn_list')) {
      return "active"
    }
  }


}
