import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../apiServices/category/category.service';
import { DareService } from '../../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';

@Component({
  selector: 'app-claim-challenge',
  templateUrl: './claim-challenge.component.html',
  styleUrls: ['./claim-challenge.component.scss']
})
export class ClaimChallengeComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  myBetDetails: any = [];
  challengeDetails: any = [];
  status: any = '1';
  memberType: any = '1';
  isEdit: any;
  paramsValue: any;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private categoryService: CategoryService,
    private dareService: DareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.getClainDetails();
    // var reqObj = {
    //   "user_id": this.authToken.user_id,
    //   "api_key": this.authToken.api_key,
    //   "play_id": this.paramsValue._value.pid
    // }
    // // this.dareService.getchallengeDetailsUsingPlayId(reqObj).subscribe(res => {
    // this.dareService.getDareDetails(reqObj).subscribe(res => {
    //   if (res.responseCode == 200) {
    //     this.loading = this.showError = false;
    //     this.myBetDetails = res.details;
    //     // this.myBetDetails = JSON.parse(localStorage.getItem('dare'));
    //   } else {
    //     this.toastr.error(res.successMessage);
    //     this.loading = false;
    //   }
    // }, (err) => {
    //   console.log(err);
    // });
  }

  getClainDetails() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    this.dareService.claimDares(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        // this.isEdit = res.status;
        this.challengeDetails = res.claim_detail;
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  handleFileSelect(evt) {
    var files = evt.target.files;
    if (files.length === 0)
      return;
    const fsize = files[0].size;
    const file = Math.round((fsize / 1024 / 1024));
    var mimeType = files[0].type;
    console.log('call size====', fsize, file, mimeType)
    if (!(file <= 100)) {
      return this.toastr.warning("File too Big, please select a file less than 100mb")
    }
    var reader = new FileReader();
    // this.imagePath = files;
    this.myForm.image = files[0];
    reader.readAsDataURL(files[0]);
    // reader.onload = (_event) => {
    //   this.profile.image = reader.result;
    // }

  }
  onSubmit() {
    if (!this.myForm.image) {
      return this.toastr.error('Please upload proof.')
    }
    if (!this.myForm.description) {
      return this.toastr.error('Write something about claim.')
    }
    this.loading = true;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid,
      "group_id": this.challengeDetails.group_id,
      "description": this.myForm.description,
      "image": this.myForm.image
    }
    this.dareService.createClaimDares(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        // this.isEdit = res.status;
        // this.challengeDetails = res.claim_detail;
        this.toastr.success(res.successMessage);
        this.router.navigate(['/' + this.challengeDetails.play_category_id + '/challenges']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
      this.toastr.error(err.statusText);
    });
  }

}
