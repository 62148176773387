import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
// import { AuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import * as $ from 'jquery';
// import {} from "googlemaps";
declare module 'googlemaps';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('gmap', { static: false }) gmapElement: any;
  map: google.maps.Map;

  isTracking = false;
  address: string = '';
  currentLat: any;
  currentLong: any;
  isShowPassword = false;
  myForm: any = {};
  loading: boolean = false;
  showError: boolean = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private socialAuthService: AuthService
  ) { }

  ngOnInit() {
    this.authService.logout();
    this.findMe();
    $(".loader").show().delay(5000).queue(function (n) {
      $(this).fadeOut();
      n();
    });
  }
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  showPosition(position) {
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;
    console.log('current location======', position)
    this.getAddress(this.currentLat, this.currentLong)
  }
  getAddress(latitude, longitude) {
    var geocoder = geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          // this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  toggleFieldTextType() {
    this.isShowPassword = !this.isShowPassword;
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        console.log(socialPlatform + " sign in data : ", userData);
        this.myForm = userData;
        this.myForm.social_type = socialPlatform || userData.provider;
        this.myForm.social_id = userData.id;
        this.myForm.user_name = userData.name;
        this.myForm.profile_pic = userData.image;
        this.myForm.latitude = this.currentLat;
        this.myForm.longitude = this.currentLong;
        this.myForm.location = this.address;
        this.myForm.deviceType = this.myForm.deviceToken = 'Web'
        this.userService.socialLogin(this.myForm).subscribe(res => {
          if (res.responseCode == 200) {
            this.loading = this.showError = false;
            this.storage.saveLoginUserDetails(res.userDetails);
            this.toastr.success(res.successMessage);
            this.router.navigate(['/dashboard']);
          } else {
            this.toastr.error(res.successMessage);
            this.loading = false;
          }
        }, (err) => {
          console.log(err);
        });
      }
    );
  }

  onSubmit(valid) {
    this.showError = true;
    if (valid) {
      this.loading = true;
      this.myForm.latitude = this.currentLat;
      this.myForm.longitude = this.currentLong;
      this.myForm.location = this.address;
      this.myForm.deviceType = this.myForm.deviceToken = 'Web'
      this.userService.login(this.myForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.storage.saveLoginUserDetails(res.userDetails);
          this.toastr.success(res.successMessage);
          if (res.userDetails.opt_status == 1) {
            localStorage.setItem('countAlert', JSON.stringify({ user_id: res.userDetails.user_id, count: 0 }))
            this.router.navigate(['/allDares']);
            localStorage.setItem('whichScreen', 'login');
          } else {
            this.router.navigate(['/verifyOtp']);
            localStorage.setItem('whichScreen', 'login');
          }
        } else {
          this.toastr.error(res.successMessage);
          this.loading = this.showError = false;
        }
      }, (err) => {
        console.log(err);
        this.toastr.error('Server not responding please try again.');
        this.loading = this.showError = false;
      });
    }
  }

}
