import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
@Injectable()
export class LocalStorageService {
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }
    saveData(key: string, data: any): void {
        sessionStorage.setItem(key, JSON.stringify(data));
    }
    getData(key: string): void {
        return JSON.parse(sessionStorage.getItem(key));
    }
    removeData(key: string): void {
        sessionStorage.removeItem(key);
    }

    saveLoginUserDetails(data: any): void {
        // console.log('data----',data)
        localStorage.setItem('l_u_d', JSON.stringify({
            api_key: data.api_key,
            mobile: data.mobile,
            email: data.email,
            user_id: data.user_id,
            user_name: data.user_name,
            qualification: data.qualification,
            otp: data.otp,
            address: data.location,
            dob: data.dob,
            aboutMe: data.aboutMe,
            longitude: data.longitude,
            latitude: data.latitude,
            location: data.location,
            image: data.image,
            dare_per_user: data.dare_per_user,
            name: data.name,
            notificationSetting: data.notificationSetting
        }));

        localStorage.setItem('LoggedIn', 'true');
    }
    public saveChatsWithPagination(chats: any, pagination: any, STORAGE_KEY: string): void {
        const data = { chats, pagination };
        // insert updated array to local storage
        var room_chats = this.storage.get(STORAGE_KEY);
        if (room_chats) {
            console.log("append room chats...");
            var updated_chats = {
                pagination: pagination,
                chats: chats
            }
            this.storage.set(STORAGE_KEY, updated_chats);
        } else {
            console.log("Save new chat...");
            this.storage.set(STORAGE_KEY, data);
        }
    }

    public saveNewMessage(message: any): void {
        // get data from local storage                
        var chatWithPagination = this.storage.get(message.roomId);
        // push new message to chats array
        if (chatWithPagination && chatWithPagination.chats) {
            chatWithPagination.chats.push(message);
            // insert updated array to local storage
            console.log("Save new message ....");
            this.storage.set(message.roomId, chatWithPagination);
        }
    }

    public updateRoomChats(messages: any, STORAGE_KEY: string): void {
        var chatWithPagination = this.storage.get(STORAGE_KEY);
        chatWithPagination.chats = messages;
        console.log("Update room chats ....");
        this.storage.set(STORAGE_KEY, chatWithPagination);
    }

    public getChatsWithPagination(STORAGE_KEY: string) {
        // get data from local storage 
        console.log("get room chats ....");
        var defaultValue = { chats: [], pagination: {}, callApi: true };
        var data = this.storage.get(STORAGE_KEY);
        if (data) {
            if (data.pagination.page < data.pagination.pages) {
                data['callApi'] = true;
            } else {
                data['callApi'] = false;
            }
            return data;
        } else {
            return defaultValue;
        }
    }
}