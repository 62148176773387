import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {
  backendUrl: any = environment.backendUrl;

  constructor(
    private http: HttpClient
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  };


  addChallenge(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/userCreateChallenge`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  deleteChallenge(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/deleteChallenge`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getActiveChallenges(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myActiveChallengeList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getActiveChallengestListUsingCategoryId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/activeChallengeDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getChallengesRequestList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myChallengeRequestList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getChallengeRequestListUsingCategoryId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getRequestChallengeDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  acceptDeclineRequestForChallenge(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/acceptDeclineRequest`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getFriendsListUsingLocation(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getNearbyUserList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  challengeWonList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myChallengeWonList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  challengesLossList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myChallengeLossList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  challengeDetails(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myChallengeDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  updateChallengeDetails(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/updateChallenege`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getChallengeWonDetailList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareChallengeDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getChallengeEarnList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myChallengeEarningList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getChallengeEarnDetailsList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myChallengeEarningDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getMemberListUsingPlayId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/userDareJoinList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }
  
}
