import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../apiServices/dare/dare.service';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';

@Component({
  selector: 'app-all-request-list',
  templateUrl: './all-request-list.component.html',
  styleUrls: ['./all-request-list.component.scss']
})
export class AllRequestListComponent implements OnInit {

  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  challengeRequestList: any = [];
  dareRequestList: any = [];
  show_to: any = '1';
  dare_page_no: any = '1';
  challenge_page_no: any = '1';
  page_size: any = '9';
  darePagination: any = {};
  challengePagination: any = {};
  showDare: boolean = true;
  apiCalled: any = false;
  showChallenge: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private dareService: DareService,
    private challengeService: ChallengeService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    this.getDareRequest();
    // this.getChallengeRequest();
  }

  getDareRequest() {
    localStorage.setItem('dare_show_to', this.show_to);
    this.showDare = true;
    this.showChallenge = this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "show_to": this.show_to,
      "page_no": this.dare_page_no,
      "page_size": this.page_size
    }
    this.dareService.dareRequestList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.dareRequestList = res.myDareList;
        this.darePagination = res.pagination;
        this.apiCalled = true;
        console.log('res--==--dare---===----', this.dareRequestList)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getChallengeRequest() {
    this.showDare = this.apiCalled = false;
    this.showChallenge = true;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": this.challenge_page_no,
      "page_size": this.page_size
    }
    this.challengeService.getChallengesRequestList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengeRequestList = res.myChallengeList;
        this.challengePagination = res.pagination;
        this.apiCalled = true;
        console.log('res--==--challenge---===----', this.challengeRequestList)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  darePageChanged(event) {
    this.dare_page_no = event;
    this.ngOnInit();
  }
  challengePageChanged(event) {
    this.challenge_page_no = event;
    this.getChallengeRequest();
  }

}
