import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoineService } from '../../apiServices/coine/coine.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import { NotificationService } from '../../apiServices/notification/notification.service';

@Component({
  selector: 'app-coin-store',
  templateUrl: './coin-store.component.html',
  styleUrls: ['./coin-store.component.scss']
})
export class CoinStoreComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  myCoineList: any = [];
  status: any = '1';
  memberType: any = '1';
  getCoinCount: any = '';
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private notificationService: NotificationService,
    private coineService: CoineService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    this.getNotificationCounts();
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
      // "playType": "1",
      // "status": this.status, //"2",
      // "memberType": this.memberType //"1"
    }
    this.coineService.getCoinsList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.myCoineList = res.coinList;
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getNotificationCounts() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.notificationService.getNotificationCount(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.getCoinCount = res.coinwalletcount;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
