import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscussionService } from '../../apiServices/discussion/discussion.service';
import { ToastrService } from 'ngx-toastr';
import { ScrollToBottomDirective } from '../../_directives';
import { LocalStorageService } from '../helper/localStorage.service';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {
  windowScrolled: boolean;
  @ViewChild(ScrollToBottomDirective, { static: true })
  scroll: ScrollToBottomDirective;

  discussionList: any = [];
  loading: boolean = false;
  isCheck: boolean = false;
  showError: boolean = false;
  submitted: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  page_no: any = '1';
  page_size: any = '10';
  paramsValue: any;
  comment: any = "";
  isScroll: boolean = true;
  apiCalled: boolean = false;
  date_now: any;
  date_future: any;

  constructor(
    private discussionService: DiscussionService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.getDiscussionList();
  }

  getDiscussionList() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "play_id": this.paramsValue._value.pid,
      "page_no": this.page_no,
      "page_size": this.page_size,
      "api_key": this.authToken.api_key
    }
    console.log('call discussion', reqObj)
    this.discussionService.getDiscussionList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.apiCalled = true;
        this.loading = this.showError = false;
        this.discussionList = res.myDareDetail.reverse();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  sendMessage() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "play_id": this.paramsValue._value.pid,
      "comment": this.comment,
      "api_key": this.authToken.api_key
    }
    console.log('call comment', reqObj)
    this.discussionService.sendDiscussion(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.discussionList = res.myDareDetail;
        this.comment = "";
        this.getDiscussionList();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  gettimeDiff(createAt) {
    this.date_future = new Date().getTime();
    this.date_now = new Date(createAt);
    var seconds = Math.floor((this.date_future - (this.date_now)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    return days > 0 ? `${days} days` : hours > 0 ? `${hours} hours` : minutes > 0 ? `${minutes} minutes` : seconds > 0 ? `${seconds} seconds` : '';
  }

}
