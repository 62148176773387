import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ChatService } from '../../../apiServices/chat/chat.service';
import { updateHeaderService } from '../../../_service'

@Component({
  selector: 'app-dare-list-using-category',
  templateUrl: './dare-list-using-category.component.html',
  styleUrls: ['./dare-list-using-category.component.scss']
})
export class DareListUsingCategoryComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  callApi: boolean = false;
  apiCalled: boolean = false;
  apiCalledForMemberList: boolean = false;
  myDareList: any = [];
  pagination: any = {};
  memberList: any = [];
  participationMemberList: any = [];
  member: any = {};
  participationMember: any = {};
  status: any = '1';
  index: any = 0;
  paramsValue: any;
  memberType: any = '1';
  page_no: any = '1';
  page_size: any = '9';
  dare_filter: any = '';
  config: any;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'Are you sure, you want to delete this dare?';
  popoverInactivateMessage: string = 'Are you sure, you want to deactivate this user?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  title: any = '';
  isClaimStatusBtnHide: boolean = false;
  constructor(
    private dareService: DareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public ngxSmartModalService: NgxSmartModalService,
    private storage: LocalStorageService,
    private chatService: ChatService,
    private header: updateHeaderService
  ) {
    this.paramsValue = this.route.params;
    this.title = this.paramsValue._value.dareType == 'alldare' ? 'ALL' : 'My'
  }

  ngOnInit() {
    this.apiCalled = false;
    var show_to = localStorage.getItem('dare_show_to')
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "dare_type": this.paramsValue._value.dareType ? this.paramsValue._value.dareType : "mydare",
      "show_to": show_to,//"1",
      "page_no": this.page_no,
      "dare_filter": this.dare_filter,
      "page_size": this.page_size,
      "category_id": this.paramsValue._value.categoryId
    }
    // console.log('check--- req--', reqObj);
    this.dareService.getActiveBetsCategoryID(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.myDareList = res.myDareDetail;
        for (let i = 0; i < this.myDareList.length; i++) {
          if (this.myDareList[i].dare_active == '1' || this.myDareList[i].is_no_friend == 1) {
            this.isClaimStatusBtnHide = true;
          } else {
            this.isClaimStatusBtnHide = false;
          }
          if (this.myDareList[i].user_id == this.authToken.user_id) {
            this.myDareList[i]['isDelete'] = true;
          } else {
            this.myDareList[i]['isDelete'] = false;
          }
          //  const element = this.myDareList[i];
          var claimActiveDate = moment(new Date(this.myDareList[i].closed_date_time)).format('YYYY-MM-DD HH:mm:ss');
          var claimActiveDateAfterAddClaimHours = moment(new Date(this.myDareList[i].closed_date_time)).add(this.myDareList[i].claim_hour, 'hours').format('YYYY-MM-DD HH:mm:ss');
          var claimStatusCheck = moment(new Date(this.myDareList[i].closed_date_time)).add((this.myDareList[i].claim_hour + 4), 'hours').format('YYYY-MM-DD HH:mm:ss');
          var startDate = moment(this.myDareList[i].start_date).format('YYYY-MM-DD') + " " + this.myDareList[i].start_time
          startDate = moment(new Date(startDate)).format('X');
          var currentDate = moment().format('X');
          // if (Number(moment(new Date(claimActiveDate)).format('X')) < Number(currentDate)) {
          // console.log('claimActiveDateAfterAddClaimHours===', claimActiveDateAfterAddClaimHours)
          if (Number(moment(new Date(claimActiveDateAfterAddClaimHours)).format('X')) < Number(currentDate)) {
            this.myDareList[i]['isClaim'] = false;
            this.myDareList[i]['isProgress'] = false;
          }
          //console.log('claimActiveDate1====', claimActiveDate, moment(new Date(claimActiveDate)).format('X') < currentDate)
          //console.log('claimActiveDate2====', claimActiveDate, moment().format('YYYY-MM-DD HH:mm:ss'))
          if (moment(new Date(claimStatusCheck)).format('X') < currentDate) {
            this.myDareList[i]['isClaimStatusBtn'] = false;
          }
        }
        this.pagination = res.pagination;
        this.apiCalled = true;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  pageChanged(event) {
    this.page_no = event;
    this.ngOnInit();
  }
  checkStatus(dare, btnType) {
    var startDate = moment(dare.start_date).format('YYYY-MM-DD') + " " + dare.start_time
    var endDate = moment(dare.closed_date).format('YYYY-MM-DD') + " " + dare.closed_time
    // console.log('startDate===1', startDate);
    startDate = moment(new Date(startDate.replace(/-/g, "/"))).format('X');
    endDate = moment(new Date(endDate.replace(/-/g, "/"))).format('X');
    var currentDate = moment().format('X');
    var claimStatusCheck = moment(new Date(dare.closed_date_time)).add((dare.claim_hour + 4), 'hours').format('YYYY-MM-DD HH:mm:ss');
    claimStatusCheck = moment(new Date(claimStatusCheck)).format('X');
    console.log('startDate===', startDate)
    console.log('currentDate===', currentDate)
    if (btnType == 'progress') {
      // if (moment().format('YYYY-MM-DD') > moment(new Date(dare.start_date.replace(/-/g, "/"))).format('YYYY-MM-DD')) {
      this.router.navigate([`/dare_detail/${dare.play_id}`]);
      // this.router.navigate([`/view_dare_details/${dare.play_id}`]);
      // if (Number(currentDate) > Number(startDate) && Number(endDate) < Number(currentDate)) {
      //   this.toastr.info('Dare has already closed.')
      // } else {
      //   if (startDate <= currentDate) {
      //     this.router.navigate([`/dare_detail/${dare.play_id}`]);
      //   } else {
      //     this.toastr.info('This will enable after the dare start.')
      //   }
      // }
    } else if (btnType == 'isClaim') {
      if (Number(claimStatusCheck) < Number(currentDate)) {
        this.toastr.info('Dare time ended, you cannot create a claim now.');
      } else {
        console.log('check timestamp====', Number(startDate) < Number(currentDate), startDate, currentDate)
        if (Number(startDate) < Number(currentDate)) {
          this.storeData(dare);
          if (dare.claim_status == 1) {
            this.toastr.success('You have already created a claim.');
          } else {
            this.router.navigate(['/claim_dare/' + dare.play_id]);
          }
        } else {
          this.toastr.info('This will enable after the dare start.')
        }
      }
    } else if (btnType == 'isClaimStatusBtn') {
      if (Number(startDate) < Number(currentDate)) {
        this.router.navigate([`/view_claim/${dare.play_id}`]);
      } else {
        this.toastr.info('This will enable after the dare start.')
      }
    }
  }
  checkServiceCall() {
    this.header.checkFunction(true);
    // var coint = this.header.getCoin();
  }
  createClaim(dare) {
    if (dare.claim_status == 1) {
      this.toastr.success('You have already created a claim.');
    } else {
      this.router.navigate(['/claim_dare/' + dare.play_id]);
    }
  }
  openModal(dare) {
    this.myForm.play_id = dare.play_id
    this.ngxSmartModalService.open('myModal');
  }
  getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }
  getDareMemberList(play_id) {
    this.apiCalledForMemberList = false;
    this.memberList = [];
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": play_id
    }
    this.dareService.getMemberListUsingPlayId(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.memberList = res.userList
        // this.ngxSmartModalService.open('viewMember');
        if (res && res.userList != 0) {
          this.member = this.memberList[0];
        }
        this.apiCalledForMemberList = true;
        this.callApi = !this.callApi;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getParticipationRequestList(play_id) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": play_id
    }
    this.dareService.dareParticipationUserDareJoinListList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.participationMemberList = res.userList;
        if (res && res.userList != 0) {
          this.participationMember = this.participationMemberList[0];
        }
        this.callApi = !this.callApi;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  next() {
    var index = this.index + 1;
    if (index <= this.memberList.length - 1) {
      this.index = index;
      this.member = this.memberList[this.index];
    }
  }
  previews() {
    var i = this.index - 1;
    this.index = i == -1 ? 0 : i;
    this.member = this.memberList[this.index]
  }

  nextParticipation() {
    var index = this.index + 1;
    if (index <= this.participationMemberList.length - 1) {
      this.index = index;
      this.participationMember = this.participationMemberList[this.index];
    }
  }
  previewsParticipation() {
    var i = this.index - 1;
    this.index = i == -1 ? 0 : i;
    this.participationMember = this.participationMemberList[this.index]
  }

  delete(dare) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": dare.play_id
    }
    this.dareService.deleteDare(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.toastr.success(res.successMessage);
        this.deleteGroup(dare.play_id);
        this.header.checkFunction(true);
        this.ngOnInit();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  deleteGroup(dareId) {
    this.chatService.deleteGroup(dareId).subscribe(res => {
      console.log(res)
    }, (err) => {
      console.log(err);
    });
  }

  storeData(data) {
    localStorage.setItem('dare', JSON.stringify(data))
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    console.log(files)
    if (files.length === 0)
      return;
    const fsize = files[0].size;
    const file = Math.round((fsize / 1024 / 1024));
    var mimeType = files[0].type;
    console.log('call size====', fsize, file, mimeType)
    if (!(file <= 100)) {
      return this.toastr.warning("File too Big, please select a file less than 100mb")
    }
    var reader = new FileReader();
    // this.imagePath = files;
    this.myForm.image = files[0];
    console.log('image===', this.myForm.image)
    reader.readAsDataURL(files[0]);
  }

  submitProgress() {
    this.loading = true;
    console.log('this.myForm===', this.myForm)
    if (!this.myForm.image) {
      this.toastr.error('Please select progress report first.');
      this.loading = this.showError = false;
    } else {
      var reqObj = {
        "user_id": this.authToken.user_id,
        "api_key": this.authToken.api_key,
        "play_id": this.myForm.play_id,
        "image": this.myForm.image
      }
      this.dareService.uploadProgress(reqObj).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.ngxSmartModalService.close('myModal');
          // this.ngOnInit();
        } else if (res.responseCode == 402) {
          this.loading = false;
          this.toastr.error('Your session has expired, Please login again.');
          this.router.navigate(['/login']);
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  closeProgesspop() {
    this.ngxSmartModalService.close('myModal');
  }

}
