// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // socketUrl: 'http://localhost:8075',
  // apiUrl: 'http://localhost:8074',
  // fileUrl: 'http://localhost:8074'
  
  // backendUrl: 'http://54.147.206.206/trunk/api',
  // backendUrl: 'https://admin.dareit.com/trunk/api',
  backendUrl: '/api',



  // Node server api 
   apiUrl: 'https://dareit-api.dareit.com',
  socketUrl: 'https://dareitsocket.dareit.com',
  fileUrl: 'https://dareit-api.dareit.com/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
