import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
// import { Message } from '../model/message';
// import { Event } from '../model/event';
import { Event, Message, Room, RoomMessage, MessageWithPagination } from '../../_models';
// import * as io from 'socket.io-client';
// import { Socket } from 'ng6-socket-io';
import * as socketIo from 'socket.io-client';

// const SERVER_URL = 'http://139.59.71.107:9093';
// const apiUrl = 'http://localhost:8074';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  socketUrl: any = environment.socketUrl;
  apiUrl: any = environment.apiUrl;
  private socket;
  // socketUrl: any = 'http://localhost:8074';
  // socketUrl: any = 'socket';
  constructor(
    private http: HttpClient
  ) {
    // this.socket = io(this.url);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  };

  public initSocket(): void {
    this.socket = socketIo(this.socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
    });
  }

  public getSocket() {
    return this.socket;
  }

  public init(data): void {
    this.socket.emit(Event.INIT, data, (success) => {
      // console.log(success);
    });
  }

  public disconnect(): void {
    this.socket.disconnect();
  }


  public roomList(data): any {
    this.socket.emit(Event.GET_ROOMS, data);
  }

  public rooms(): Observable<Room> {
    return new Observable<Room>(observer => {
      this.socket.on(Event.ROOMS, (data: Room) => observer.next(data));
    });
  }

  public createGroups(data): any {
    this.socket.emit(Event.CREATE_GROUP, data);
  }

  public exitGroup(data): any {
    this.socket.emit(Event.EXIT_GROUP, data);
  }

  public addParticipants(data): any {
    this.socket.emit(Event.ADD_PARTICIPANTS, data);
  }

  public readMessage(data): any {
    this.socket.emit(Event.READ_MESSAGE, data);
  }

  public messageRead(): Observable<Message> {
    return new Observable<Message>(observer => {
      this.socket.on(Event.MESSAGE_READ, (data: Message) => observer.next(data));
    });
  }

  public newGroup(): Observable<Room> {
    return new Observable<Room>(observer => {
      this.socket.on(Event.NEW_GROUP, (data: Room) => observer.next(data));
    });
  }

  public updatedGroup(): Observable<Room> {
    return new Observable<Room>(observer => {
      this.socket.on(Event.UPDATED_GROUP, (data: Room) => observer.next(data));
    });
  }

  public chatList(data): void {
    this.socket.emit(Event.GET_CHATS, data);
  }

  public chats(): Observable<MessageWithPagination> {
    return new Observable<MessageWithPagination>(observer => {
      this.socket.on(Event.CHATS, (data: MessageWithPagination) => observer.next(data));
    });
  }

  public sendText(data): void {
    this.socket.emit(Event.SEND_MESSAGE, data);
  }

  public saveMessage(): Observable<RoomMessage> {
    return new Observable<RoomMessage>(observer => {
      this.socket.on(Event.MESSAGE_SAVE, (data: RoomMessage) => observer.next(data));
    });
  }

  public saveImageMessage(): Observable<RoomMessage> {
    return new Observable<RoomMessage>(observer => {
      this.socket.on(Event.IMAGE_MESSAGE_SAVE, (data: RoomMessage) => observer.next(data));
    });
  }

  public receiveMessage(): Observable<RoomMessage> {
    return new Observable<RoomMessage>(observer => {
      this.socket.on(Event.RECEIVE_MESSAGE, (data: RoomMessage) => observer.next(data));
    });
  }

  public deliveredMessage(): Observable<Message> {
    return new Observable<Message>(observer => {
      this.socket.on(Event.DELIVERED_MESSAGE, (data: Message) => observer.next(data));
    });
  }

  public UnDeliverMessagesSent(): Observable<RoomMessage> {
    return new Observable<RoomMessage>(observer => {
      this.socket.on(Event.UN_DELIVER_MESSAGES_SENT, (data: RoomMessage) => observer.next(data));
    });
  }

  public messageDelivered(): Observable<RoomMessage> {
    return new Observable<RoomMessage>(observer => {
      this.socket.on(Event.MESSAGE_DELIVERED, (data: RoomMessage) => observer.next(data));
    });
  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, () => observer.next());
    });
  }


  getRoomList(userId): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/chat/room_list?userId=` + userId).pipe(map(this.extractData));
  }

  createGroup(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/chat/create_group`, data).pipe(map(this.extractData));
  }

  deleteGroup(dareId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/chat/delete_group?dareId=` + dareId).pipe(map(this.extractData));
  }

  uploadImage(data): Observable<any> {
    //console.log('data===', data)
    var fd = new FormData();
    fd.append('roomId', data.roomId);
    fd.append('dareId', data.dareId);
    fd.append('type', data.type);
    fd.append('senderId', data.senderId);
    fd.append('timeStamp', data.timeStamp);
    fd.append('file', data.file);
    fd.append('id', data.id);
    return this.http.post(`${this.apiUrl}/api/chat/upload_image`, fd).pipe(map(this.extractData));
  }

}
