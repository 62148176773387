import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plus-btn',
  templateUrl: './plus-btn.component.html',
  styleUrls: ['./plus-btn.component.scss']
})
export class PlusBtnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
