import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../helper/localStorage.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatepickerOptions } from 'ng2-datepicker';
import * as moment from 'moment';
import { DareService } from '../../../apiServices/dare/dare.service';
import { CategoryService } from '../../../apiServices/category/category.service';
import * as $ from 'jquery';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-edit-bet',
  templateUrl: './edit-bet.component.html',
  styleUrls: ['./edit-bet.component.scss']
})
export class EditBetComponent implements OnInit {

  myForm: any = {
    show_to_all: true,
    tag_id: []
  };
  tags:any = [];
  show_to_all: any = true;
  showCategoryError: boolean = false;
  categoryForm: any = {};
  minTime: any = '12:00 am';
  minTargetTime: any = '12:00 am';
  categoryList: any = [];
  usersList: any = [];
  selectUndefinedValue: any;
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  currentBookingDate: any = moment().add(30, 'minutes').subtract(1, 'days');
  date: any = new Date(this.currentBookingDate);
  showModal: boolean = false;
  paramsValue: any;
  startDateOptions: DatepickerOptions = {
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(this.currentBookingDate), // Minimal selectable date
    maxDate: new Date(2033, 1, 1), // Maximal selectable date
    barTitleIfEmpty: 'Start Date',
    placeholder: 'Start Date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  targetDateOptions: DatepickerOptions = {
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(this.currentBookingDate), // Minimal selectable date
    maxDate: new Date(2033, 1, 1), // Maximal selectable date
    barTitleIfEmpty: 'Target Date',
    placeholder: 'Target Date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  userMaxLimit: any = "";
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private dareService: DareService,
    private categoryService: CategoryService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  // ngOnInit() {
  //   var reqObj = {
  //     "user_id": this.authToken.user_id,
  //     "api_key": this.authToken.api_key
  //   }
  //   this.categoryService.getCategoryList(reqObj).subscribe(res => {
  //     if (res.responseCode == 200) {
  //       this.loading = this.showError = false;
  //       this.categoryList = res.categoryList;
  //       // console.log('res----', this.categoryList)
  //       // this.myForm = JSON.parse(localStorage.getItem('dare'));
  //       // this.myForm.title = this.myForm.play_name
  //       this.getDareDetails(this.paramsValue._value.pid);

  //       this.myForm.dare_description = this.myForm.play_decription
  //       this.myForm.start_date = new Date(this.myForm.start_date)
  //       this.myForm.target_date = new Date(this.myForm.closed_date)
  //       this.myForm.target_time = new Date(this.myForm.closed_time)
  //       // this.myForm.show_to = this.myForm.friend_id ? 2 : 1;
  //     } else {
  //       this.toastr.error(res.successMessage);
  //       this.loading = false;
  //     }
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }
  ngOnInit() {
    // this.findMe();
    this.userMaxLimit = this.authToken.dare_per_user;
    this.categoryListFunction();
    this.getDareDetails(this.paramsValue._value.pid);

    this.myForm.dare_description = this.myForm.play_decription
    this.myForm.start_date = new Date(this.myForm.start_date)
    this.myForm.target_date = new Date(this.myForm.closed_date)
    this.myForm.target_time = new Date(this.myForm.closed_time)
  }
  categoryListFunction() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.categoryService.getCategoryList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.categoryList = res.categoryList;
        // console.log('res----', this.categoryList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }


  openModal() {
    if (this.myForm.show_to == '2' || this.myForm.show_to == 2) {
      this.showModal = !this.showModal;
      this.getFriendList();
    }
  }

  checkTimeValidation() {
    var selectDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
    var currentDate = moment().format('YYYY-MM-DD');
    if (selectDate == currentDate) {
      this.minTime = moment().add(30, 'minutes').format('h:mm a');
      //this.myForm.start_time = moment().add(1, 'hours').format('h:mm a');
    } else {
      this.minTime = '12:00 am';
    }
    this.checkTargetTime();
    this.checkCurrentDateTime();
  }
  // checkTimeValidationForTarget() {
  //   var selectDate = moment(this.myForm.target_date).format('YYYY-MM-DD');
  //   var currentDate = moment().format('YYYY-MM-DD');
  //   console.log('check----', selectDate + " " + this.myForm.start_time)
  //   this.checkTargetTime();
  // }
  checkTimeValidationForTarget() {
    var selectDate = moment(this.myForm.target_date).format('YYYY-MM-DD');
    var currentDate = moment().format('YYYY-MM-DD');
    var startDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
    console.log('check----', selectDate + " " + this.myForm.start_time)
    if (Number(Date.parse(startDate) / 1000) == Number(Date.parse(selectDate) / 1000)) {
      this.minTargetTime = moment().add(35, 'minutes').format('h:mm a');
      //this.myForm.start_time = moment().add(1, 'hours').format('h:mm a');
    }
    this.checkTargetTime();
  }
  checkTargetTime() {
    var startTime = moment(this.myForm.start_date).format('YYYY-MM-DD') + " " + this.myForm.start_time;
    var targetTime = moment(this.myForm.target_date).format('YYYY-MM-DD') + " " + this.myForm.target_time;

    if (Number(Date.parse(targetTime) / 1000) <= Number(Date.parse(startTime) / 1000)) {
      this.toastr.error('Target date and time should be greater than Start date and time.');
      return true;
    } else {
      return false;
    }
  }
  getDareDetails(play_id) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": play_id
    }
    this.dareService.getDareDetails(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.myForm = res.details;
        if (this.myForm.show_to == '2') {
          this.myForm.show_to = 2
        } else {
          this.myForm.show_to = 1
        }
        console.log('===show_to====', this.myForm.show_to)
        this.myForm.title = this.myForm.play_name
        this.myForm.start_time = this.myForm.start_time
        this.myForm.dare_description = this.myForm.play_decription
        this.myForm.start_date = new Date(this.myForm.start_date)
        this.myForm.target_date = new Date(this.myForm.closed_date)
        this.myForm.target_time = this.myForm.closed_time;
        this.show_to_all = this.myForm.show_to_all == "0" ? false : true;
        if (res.details && res.details.tag_list) {
          this.tags = this.myForm.tag_list.split(",");
        } else {
          this.tags = [];
        }
        var selectDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
        var currentDate = moment().format('YYYY-MM-DD');
        if (selectDate == currentDate) {
          this.minTime = moment().add(30, 'minutes').format('h:mm a');
          //this.myForm.start_time = moment().add(1, 'hours').format('h:mm a');
        } else {
          this.minTime = '12:00 am';
        }
        console.log('show_to----', this.myForm)
        // this.myForm.bet_close_limit = this.myForm.time_limit;
        // this.checkTimeValidationForTarget();
        // this.checkTimeValidation();
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  };

  getFriendList() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": "1",
      "page_size": "20"
    }
    this.dareService.getFriendsListUsingLocation(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.usersList = res.getFriendList;
        for (var i = 0; i < this.usersList.length; i++) {
          this.usersList[i]['isSelected'] = false;
        }
        console.log('res----list', this.usersList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  addFriend(user) {
    user.isSelected = !user.isSelected;
  }

  closeModal() {
    this.showModal = !this.showModal;
    $('.modal').fadeOut();
    $('.modal-dialog').fadeOut();
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM' || modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }

  checkCurrentDateTime() {
    var startDateTime = moment(this.myForm.start_date).format('YYYY-MM-DD') + " " + this.myForm.start_time
    var currentDateTime = moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    // console.log('currentDateTime===1', currentDateTime);
    // console.log('startDateTime===1', startDateTime);
    startDateTime = moment(new Date(startDateTime.replace(/-/g, "/"))).format('X');
    currentDateTime = moment(new Date(currentDateTime.replace(/-/g, "/"))).format('X');
    console.log('condi====',Number(startDateTime) <= Number(currentDateTime))
    if (Number(startDateTime) <= Number(currentDateTime)) {
      this.toastr.error('The start date and time must be greater than or equal to the current date and time.');
      return false;
    }else{
      return true;
    }
  }
  
  onSubmit(valid) {
    this.showError = true;
    if (valid && this.checkCurrentDateTime()) {
      this.loading = true;
      this.myForm.type = "1";
      this.myForm.user_id = this.authToken.user_id
      // this.myForm.date = this.myForm.start_date
      this.myForm.start_time = this.convertTime12to24(this.myForm.start_time);
      var splitTime = this.myForm.start_time.split(":")
      var updateDateTime = new Date(this.myForm.start_date).setHours(splitTime[0], splitTime[1]);
      // console.log('check updateDateTime====', updateDateTime);
      this.myForm.date = moment(updateDateTime).format('YYYY-MM-DD H:mm:ss');

      this.myForm.target_time = this.convertTime12to24(this.myForm.target_time);
      var splitTargetTime = this.myForm.target_time.split(":")
      var updateTargetDateTime = new Date(this.myForm.target_date).setHours(splitTargetTime[0], splitTargetTime[1]);
      this.myForm.target_date = moment(updateTargetDateTime).format('YYYY-MM-DD H:mm:ss');
      this.myForm.bet_close_limit = moment(updateTargetDateTime).format('YYYY-MM-DD H:mm:ss');

      this.myForm["api_key"] = this.authToken.api_key
      if (this.myForm.show_to && (this.myForm.show_to == 2 || this.myForm.show_to == '2')) {
        var friend_id = '';
        for (var i = 0; i < this.usersList.length; i++) {
          if (this.usersList[i].isSelected) {
            friend_id = friend_id ? (friend_id + ',' + this.usersList[i].user_id) : this.usersList[i].user_id
          }
        }
        this.myForm['friend_id'] = friend_id;
        this.myForm['friend_type'] = 'nearbyuser';
      }
      console.log('-------check-------', Number(this.myForm.user_limit) >= 2)
      // if (this.myForm && this.myForm.user_limit && !(Number(this.myForm.user_limit) >= Number(this.authToken.dare_per_user))) {
      if (this.myForm && this.myForm.user_limit && !(Number(this.myForm.user_limit) >= 2)) {
        this.loading = this.showError = false;
        return this.toastr.error('User limit should be greater than 1.');
      }
      // var currentDate = moment().format('YYYY-MM-DD HH:MM');
      // var startTime = moment(this.myForm.start_date).format('YYYY-MM-DD') + " " + this.myForm.start_time;
      // if (Number(Date.parse(startTime) / 1000) >= Number(Date.parse(currentDate) / 1000)) {
      //   this.toastr.error('Start time should be greater than current time.');
      //   this.loading = false
      //   return true;
      // }
      this.myForm.tag_id = this.tags.toString()
      var checkTime = this.checkTargetTime()
      if (checkTime) {
        console.log('checkTime===2', checkTime)
        this.loading = this.showError = false;
        //return this.toastr.error('Target date and time should be greater than start date and time.');
      } else {
        this.myForm.show_to_all = this.show_to_all == true ? "1" : "0";
        console.log('this.myForm====1', this.myForm)
        this.dareService.updatePlayType(this.myForm).subscribe(res => {
          if (res.responseCode == 200) {
            this.loading = this.showError = false;
            this.toastr.success(res.successMessage);
            this.router.navigate(['/myDares']);
          } else {
            this.toastr.error(res.successMessage);
            this.loading = false;
          }
        }, (err) => {
          console.log(err);
          this.loading = this.showError = false;
          this.toastr.error('Some server error has occurred. Please try again');
        });
      }
    }
  }
  onClickedOutside(e) {
    console.log('Clicked outside:', e);
  }
  createCategory(valid) {
    console.log('valid===', valid)
    this.showCategoryError = true;
    if (valid) {
      this.loading = true;
      this.categoryForm.user_id = this.authToken.user_id
      this.categoryForm["api_key"] = this.authToken.api_key
      this.categoryService.addCategory(this.categoryForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showCategoryError = false;
          this.toastr.success(res.successMessage);
          this.ngxSmartModalService.close('myModalCatgeory');
          this.showModal = false;
          // $("#viewMember").fadeOut();
          // this.showModal = !this.showModal;
          this.categoryListFunction();
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

}
