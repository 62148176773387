import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../helper/localStorage.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatepickerOptions } from 'ng2-datepicker';
import * as moment from 'moment';
import { ChallengeService } from '../../../apiServices/challenge/challenge.service';
import { CategoryService } from '../../../apiServices/category/category.service';
declare module 'googlemaps';
import * as $ from 'jquery';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ChatService } from '../../../apiServices/chat/chat.service';


@Component({
  selector: 'app-add-challenge',
  templateUrl: './add-challenge.component.html',
  styleUrls: ['./add-challenge.component.scss']
})
export class AddChallengeComponent implements OnInit {
  @ViewChild('gmap', { static: false }) gmapElement: any;
  map: google.maps.Map;

  isTracking = false;
  address: string = '';
  currentLat: any;
  currentLong: any;
  searchFriends: string = '';

  groupName: any;
  dareId: any;
  adding = false;
  selectUser: any = [];

  myForm: any = {
    show_to_all: true,
    tag_id: []
  };
  tags:any = [];
  showCategoryError: boolean = false;
  categoryForm: any = {};
  categoryList: any = [];
  usersList: any = [];
  selectedUsersList: any = [];
  selectUndefinedValue: any;
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  currentBookingDate: any = moment().add(30, 'minutes').subtract(1, 'days');
  date: any = new Date(this.currentBookingDate);
  showModal: boolean = false;
  page_no: any = '1';
  page_size: any = '10';
  pagination: any = {};

  startDateOptions: DatepickerOptions = {
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(this.currentBookingDate), // Minimal selectable date
    maxDate: new Date(2033, 1, 1), // Maximal selectable date
    barTitleIfEmpty: 'Start Date',
    placeholder: 'Start Date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  targetDateOptions: DatepickerOptions = {
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(this.currentBookingDate), // Minimal selectable date
    maxDate: new Date(2033, 1, 1), // Maximal selectable date
    barTitleIfEmpty: 'Target Date',
    placeholder: 'Target Date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  minTime: any = '12:00 am';
  minTargetTime: any = '12:00 am';
  filtered_user: any = [];
  getAllUserList: any = [];
  show_to_all: any = true;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private challengeService: ChallengeService,
    private categoryService: CategoryService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private socketService: ChatService,
  ) { }

  ngOnInit() {
    this.findMe();
    this.categoryListFunction();
  }
  categoryListFunction() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.categoryService.getCategoryList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.categoryList = res.categoryList;
        console.log('res----', this.categoryList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  showPosition(position) {
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;
    // console.log('chec--------', this.currentLat, position)
    this.getFriendList();
  }

  openModal(valid) {
    console.log('check----', valid)
    var checkTime = this.checkTargetTime()
    if (checkTime) {
      console.log('checkTime===2', checkTime)
      this.loading = this.showError = false;
      //return this.toastr.error('Target date and time should be greater than start date and time.');
    } else {
      this.showError = true;
      // if (valid) {
      // this.loading = true;
      this.showModal = !this.showModal;
      this.ngxSmartModalService.open('myModal');
      if (this.usersList.length == 0) {
        this.getFriendList();
      }
      // }
    }
  }
  mergeSelectedUser() {
    if (this.usersList && this.usersList.length) {
      this.filtered_user = [];
      this.usersList.forEach((usr) => {
        var selected = this.selectedUsersList.filter((val) => {
          return usr.user_id == val.user_id;
        })

        if (!selected || selected.length == 0) {
          this.filtered_user.push(usr);
        }
      })
      return [...this.selectedUsersList, ...this.filtered_user];
    } else {
      return [...this.selectedUsersList];
    }
  }
  getFriendList() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": this.page_no,
      "page_size": this.page_size,
      "latitude": this.currentLat,
      "longitude": this.currentLong,
      "search": this.searchFriends
    }
    console.log('print====>>', reqObj)
    this.challengeService.getFriendsListUsingLocation(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.usersList = res.getFriendList;
        this.pagination = res.pagination;
        for (var i = 0; i < this.usersList.length; i++) {
          this.usersList[i]['isSelected'] = false;
        }
        this.getAllUserList = this.mergeSelectedUser();
        // console.log('res----list', this.usersList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  onScroll() {
    this.page_size = Number(this.page_size) + 10;
    console.log("Scrolled", this.page_size);
    this.getFriendList();
  }
  pageChanged(event) {
    this.page_no = event;
    this.getFriendList();
  }
  addFriend(user) {
    console.log('this.usersList.length===', user.isSelected, this.selectedUsersList.length)
    if (this.selectedUsersList.length == 1 && !user.isSelected) {
      this.toastr.error('You can select only one friend at a time.')
    } else if (this.selectedUsersList.length == 0 && !user.isSelected) {
      user.isSelected = !user.isSelected;
      this.selectedUsersList.push(user)
    } else {
      user.isSelected = !user.isSelected;
      this.selectedUsersList = [];
    }
  }

  closeModal() {
    this.showModal = !this.showModal;
    console.log('check---length==', this.selectedUsersList.length)
    if (this.selectedUsersList.length != 0) {
      this.ngxSmartModalService.close('myModal');
      // this.onSubmit();
    } else {
      this.toastr.error('Please add friend first.')
    }
  }
  clearModel() {
    this.ngxSmartModalService.close('myModal');
  }

  // checkTimeValidation() {
  //   var selectDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
  //   var currentDate = moment().format('YYYY-MM-DD');
  //   if (selectDate == currentDate) {
  //     this.minTime = moment().add(1, 'hours').format('h:mm a');
  //   } else {
  //     this.minTime = this.minTime;
  //   }
  // }
  // checkTimeValidationForTarget() {
  //   var selectDate = moment(this.myForm.target_date).format('YYYY-MM-DD');
  //   var currentDate = moment().format('YYYY-MM-DD');
  //   if (selectDate == currentDate) {
  //     this.myForm.target_time = '';
  //     this.minTargetTime = moment(new Date(selectDate + " " + this.myForm.start_time)).add(1, 'hours').format('h:mm a');
  //   } else {
  //     this.minTargetTime = this.minTargetTime;
  //   }
  // }
  checkTimeValidation() {
    var selectDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
    var currentDate = moment().format('YYYY-MM-DD');
    if (selectDate == currentDate) {
      this.minTime = moment().add(30, 'minutes').format('h:mm a');
      //this.myForm.start_time = moment().add(1, 'hours').format('h:mm a');
    } else {
      this.minTime = '12:00 am';
    }
    this.checkTargetTime();
    this.checkCurrentDateTime();
  }
  checkTimeValidationForTarget() {
    // var selectDate = moment(this.myForm.target_date).format('YYYY-MM-DD');
    // var currentDate = moment().format('YYYY-MM-DD');
    // console.log('check----', selectDate + " " + this.myForm.start_time)
    var selectDate = moment(this.myForm.target_date).format('YYYY-MM-DD');
    var currentDate = moment().format('YYYY-MM-DD');
    var startDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
    console.log('check----', selectDate + " " + this.myForm.start_time)
    if (Number(Date.parse(startDate) / 1000) == Number(Date.parse(selectDate) / 1000)) {
      this.minTargetTime = moment().add(35, 'minutes').format('h:mm a');
      //this.myForm.start_time = moment().add(1, 'hours').format('h:mm a');
    }
    this.checkTargetTime();
  }
  checkTargetTime() {
    var startTime = moment(this.myForm.start_date).format('YYYY-MM-DD') + " " + this.myForm.start_time;
    var targetTime = moment(this.myForm.target_date).format('YYYY-MM-DD') + " " + this.myForm.target_time;
    if (Number(Date.parse(targetTime.replace(/-/g, ' ')) / 1000) <= Number(Date.parse(startTime.replace(/-/g, ' ')) / 1000)) {
      this.toastr.error('Target date and time should be greater than Start date and time.');
      return true;
    } else {
      return false;
    }
  }
  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM' || modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }

  openModalCategory(type) {
    if (type == 'add') {
      this.categoryForm = {};
      this.ngxSmartModalService.open('myModalCatgeory');
    } else {
      this.ngxSmartModalService.close('myModalCatgeory');
    }
  }

  createCategory(valid) {
    console.log('valid===', valid)
    this.showCategoryError = true;
    if (valid) {
      this.loading = true;
      this.categoryForm.user_id = this.authToken.user_id
      this.categoryForm["api_key"] = this.authToken.api_key
      this.categoryService.addCategory(this.categoryForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showCategoryError = false;
          this.toastr.success(res.successMessage);
          this.ngxSmartModalService.close('myModalCatgeory');
          this.showModal = false;
          // $("#viewMember").fadeOut();
          // this.showModal = !this.showModal;
          this.categoryListFunction();
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  checkCurrentDateTime() {
    var startDateTime = moment(this.myForm.start_date).format('YYYY-MM-DD') + " " + this.myForm.start_time
    var currentDateTime = moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    // console.log('currentDateTime===1', currentDateTime);
    // console.log('startDateTime===1', startDateTime);
    startDateTime = moment(new Date(startDateTime.replace(/-/g, "/"))).format('X');
    currentDateTime = moment(new Date(currentDateTime.replace(/-/g, "/"))).format('X');
    console.log('condi====',Number(startDateTime) <= Number(currentDateTime))
    if (Number(startDateTime) <= Number(currentDateTime)) {
      this.toastr.error('The start date and time must be greater than or equal to the current date and time.');
      return false;
    }else{
      return true;
    }
  }

  onSubmit(valid) {
    console.log('valid====>>', valid)
    this.showError = true;
    if (valid && this.checkCurrentDateTime()) {
      this.loading = true;
      this.myForm.type = "1";
      this.myForm.user_id = this.authToken.user_id
      this.myForm["api_key"] = this.authToken.api_key
      var friend_id = '';
      if (this.selectedUsersList.length == 0) {
        return this.toastr.error('Please select friend.');
      } else {
        for (var i = 0; i < this.selectedUsersList.length; i++) {
          if (this.selectedUsersList[i].isSelected) {
            friend_id = friend_id ? (friend_id + ',' + this.selectedUsersList[i].user_id) : this.selectedUsersList[i].user_id
          }
        }
      }
      this.myForm['friend_id'] = friend_id;
      this.myForm['friend_type'] = 'nearbyuser';
      this.myForm.latitude = this.currentLat;
      this.myForm.longitude = this.currentLong;
      this.myForm.start_time = this.convertTime12to24(this.myForm.start_time);
      var splitTime = this.myForm.start_time.split(":")
      var updateDateTime = new Date(this.myForm.start_date).setHours(splitTime[0], splitTime[1]);
      this.myForm.start_date = moment(updateDateTime).format('YYYY-MM-DD H:mm:ss');

      this.myForm.target_time = this.convertTime12to24(this.myForm.target_time);
      var splitTargetTime = this.myForm.target_time.split(":")
      var updateTargetDateTime = new Date(this.myForm.target_date).setHours(splitTargetTime[0], splitTargetTime[1]);
      this.myForm.target_date = moment(updateTargetDateTime).format('YYYY-MM-DD H:mm:ss');
      this.myForm.challenge_close_limit = moment(updateTargetDateTime).format('YYYY-MM-DD H:mm:ss');
      this.myForm.tag_id = this.tags.toString()
      console.log('this.myForm====', this.myForm, this.showModal)
      this.myForm.show_to_all = this.show_to_all == true ? "1" : "0";
      this.challengeService.addChallenge(this.myForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.showModal = false;
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.ngxSmartModalService.close('myModal');
          this.groupName = this.myForm.title
          this.dareId = res.play_id
          this.selectUser.push(this.authToken.user_id)
          this.createGroup();
          this.router.navigate(['/challenges']);
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  public createGroup(): void {
    if (this.groupName) {
      var data = {
        userId: this.authToken.user_id,
        dareId: this.dareId,
        participants: this.selectUser.join(","),
        groupName: this.groupName,
        //file : this.file
      }
      this.adding = true;
      this.socketService.createGroup(data).subscribe(objS => {
        this.adding = false;
        if (objS.status == 200) {
          // this.router.navigate(['/chat']);
        } else {
          this.toastr.error(objS.userMessage);
        }
      }, (err) => {
        this.adding = false;
        this.toastr.error("Something went wrong");
      });
    }
    else {
      this.toastr.info("Group name and participants are mandatory.");
    }
  }

}
