import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coins-wallet',
  templateUrl: './coins-wallet.component.html',
  styleUrls: ['./coins-wallet.component.scss']
})
export class CoinsWalletComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
