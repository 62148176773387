import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NumberOnlyDirective } from './number.directive';
import { UserService } from './apiServices/user/user.service';
import { CategoryService } from './apiServices/category/category.service';
import { DareService } from './apiServices/dare/dare.service';
import { ChallengeService } from './apiServices/challenge/challenge.service';
import { CoineService } from './apiServices/coine/coine.service';
import { ChatService } from './apiServices/chat/chat.service';
import { DiscussionService } from './apiServices/discussion/discussion.service';
import { NotificationService } from './apiServices/notification/notification.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TopNavbarComponent } from './components/top-navbar/top-navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ChallengesComponent } from './components/challengeModule/challenges/challenges.component';
import { MydaresComponent } from './components/dareModule/mydares/mydares.component';
import { ChatsComponent } from './components/chats/chats.component';
import { AddNewBetsComponent } from './components/dareModule/add-new-bets/add-new-bets.component';
import { UserChatComponent } from './components/user-chat/user-chat.component';
import { ActiveBetsComponent } from './components/active-bets/active-bets.component';
import { LoginComponent } from './components/authModule/login/login.component';
import { RegistrationComponent } from './components/authModule/registration/registration.component';
// import { BetsRequestComponent } from './components/bets-request/bets-request.component';
import { DaresWonComponent } from './components/dareModule/dares-won/dares-won.component';
import { CoinStoreComponent } from './components/coin-store/coin-store.component';
import { EarningComponent } from './components/earning/earning.component';
import { ViewDareComponent } from './components/dareModule/view-dare/view-dare.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ProfileComponent } from './components/setting/profile/profile.component';
import { ProfileEditComponent } from './components/setting/profile-edit/profile-edit.component';
import { BankAccountComponent } from './components/setting/bank-account/bank-account.component';
import { AllDaresComponent } from './components/dareModule/all-dares/all-dares.component';

// import { MustMatchDirective } from './_helpers/must-match.directive';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChatUtil } from '../app/_utils/chat';
import { ScrollToBottomDirective, ScrollDirective } from './_directives';
import { updateHeaderService } from './_service';
import { RoomFilterPipe, ParticipantsFilterPipe, UserFilterPipe } from './_filters';
// import { JwPaginationComponent } from 'jw-angular-pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { StorageServiceModule } from 'ngx-webstorage-service';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { ToastrModule } from 'ngx-toastr';
import { NgDatepickerModule } from 'ng2-datepicker';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as $ from 'jquery';
import { AgmCoreModule } from '@agm/core';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { OwlModule } from 'ngx-owl-carousel';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { TagInputModule } from 'ngx-chips';
import { ClickOutsideModule } from 'ng-click-outside';
// import { MatFormFieldModule, MatSelectModule } from '@angular/material';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
// import { environment } from '../environments/environment';
// import { MessagingService } from './shared/messaging.service';
// import { SocketIoModule, SocketIoConfig } from 'ng6-socket-io';

import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angular-6-social-login";
// import social buttons module
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
//@ helper services
import { LocalStorageService } from './components/helper/localStorage.service';
import { DashboardActiveBetsComponent } from './components/dashboard-active-bets/dashboard-active-bets.component';
import { DashboardBestOfTheWeekComponent } from './components/dashboard-best-of-the-week/dashboard-best-of-the-week.component';
import { DashboardCloseBetsComponent } from './components/dashboard-close-bets/dashboard-close-bets.component';
import { DashboardEarningBetsWonComponent } from './components/dashboard-earning-bets-won/dashboard-earning-bets-won.component';
import { OtpVerificationComponent } from './components/authModule/otp-verification/otp-verification.component';
import { EditBetComponent } from './components/dareModule/edit-bet/edit-bet.component';
import { DashboardActiveChallengesComponent } from './components/dashboard-active-challenges/dashboard-active-challenges.component';
import { DareOfTheWeekComponent } from './components/dareModule/dare-of-the-week/dare-of-the-week.component';
import { CoinsWalletComponent } from './components/coins-wallet/coins-wallet.component';


import { AboutUSComponent } from './components/setting/about-us/about-us.component';
import { NewCategoryComponent } from './components/new-category/new-category.component';
import { DareRequestListComponent } from './components/dareModule/dare-request-list/dare-request-list.component';
import { ChallengeRequestListComponent } from './components/challengeModule/challenge-request-list/challenge-request-list.component';
import { AllRequestListComponent } from './components/all-request-list/all-request-list.component';
import { AllDareWonLossRequestListComponent } from './components/all-dare-won-loss-request-list/all-dare-won-loss-request-list.component';
import { AllDareWonListComponent } from './components/all-dare-won-list/all-dare-won-list.component';
import { AllDareLossListComponent } from './components/all-dare-loss-list/all-dare-loss-list.component';
import { AllChallengeWonLostRequestListComponent } from './components/all-challenge-won-lost-request-list/all-challenge-won-lost-request-list.component';
import { ChallengesListComponent } from './components/challengeModule/challenges-list/challenges-list.component';
import { AddChallengeComponent } from './components/challengeModule/add-challenge/add-challenge.component';
import { DareListUsingCategoryComponent } from './components/dareModule/dare-list-using-category/dare-list-using-category.component';
import { ViewChallengeComponent } from './components/challengeModule/view-challenge/view-challenge.component';
import { EditChallengeComponent } from './components/challengeModule/edit-challenge/edit-challenge.component';
import { ClaimDareComponent } from './components/dareModule/claim-dare/claim-dare.component';
import { ViewClaimComponent } from './components/view-claim/view-claim.component';
import { NewAllDaresComponent } from './components/new-all-dares/new-all-dares.component';
import { ClaimChallengeComponent } from './components/challengeModule/claim-challenge/claim-challenge.component';
import { AllChallengeWonListComponent } from './components/all-challenge-won-list/all-challenge-won-list.component';
import { AllChallengeLossListComponent } from './components/all-challenge-loss-list/all-challenge-loss-list.component';
import { DareChallengeEarningComponent } from './components/dare-challenge-earning/dare-challenge-earning.component';
import { DareDetailsComponent } from './components/dareModule/dare-details/dare-details.component';
import { WriteToUsComponent } from './components/write-to-us/write-to-us.component';
import { ChangePasswordComponent } from './components/setting/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/authModule/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/authModule/reset-password/reset-password.component';
import { ChallengeProgressComponent } from './components/challenge-progress/challenge-progress.component';
import { PlusBtnComponent } from './components/plus-btn/plus-btn.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';
import { ChildPolicyComponent } from './components/pages/child-policy/child-policy.component';


//App Secret = 66c7946df66db884405d1e3e6c66d4ec
//App ID = 910333262731867
//Client Token = f124b17a85dd314a471db2305bfe2744

//Google Client ID= 117645276472-ke068il16e5k7atl93jn6vnhj7vohc54.apps.googleusercontent.com
//Google Secret = 4TUVowpBl_PXcVK5Hh09vKtY

export function socialConfigs() {
  const config = new AuthServiceConfig(
    [{
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider('910333262731867')
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider('117645276472-ke068il16e5k7atl93jn6vnhj7vohc54.apps.googleusercontent.com')
    }
    ]
  );
  return config;
}

// const socketConfig: SocketIoConfig = { url: 'http://139.59.71.107:9093', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    // JwPaginationComponent,
    SidebarComponent,
    DashboardComponent,
    NumberOnlyDirective,
    ChallengesComponent,
    MydaresComponent,
    ChatsComponent,
    AddNewBetsComponent,
    UserChatComponent,
    ActiveBetsComponent,
    LoginComponent,
    RegistrationComponent,
    // BetsRequestComponent,
    DaresWonComponent,
    CoinStoreComponent,
    EarningComponent,
    ViewDareComponent,
    NotificationComponent,
    ProfileComponent,
    // MustMatchDirective,
    ScrollToBottomDirective,
    ScrollDirective,
    ProfileEditComponent,
    BankAccountComponent,
    DashboardActiveBetsComponent,
    DashboardBestOfTheWeekComponent,
    DashboardCloseBetsComponent,
    DashboardEarningBetsWonComponent,
    OtpVerificationComponent,
    EditBetComponent,
    DashboardActiveChallengesComponent,
    AllDaresComponent,
    DareOfTheWeekComponent,
    CoinsWalletComponent,
    AboutUSComponent,
    NewCategoryComponent,
    DareRequestListComponent,
    ChallengeRequestListComponent,
    AllRequestListComponent,
    AllDareWonLossRequestListComponent,
    AllDareWonListComponent,
    AllDareLossListComponent,
    AllChallengeWonLostRequestListComponent,
    ChallengesListComponent,
    AddChallengeComponent,
    DareListUsingCategoryComponent,
    ViewChallengeComponent,
    EditChallengeComponent,
    ClaimDareComponent,
    ViewClaimComponent,
    NewAllDaresComponent,
    ClaimChallengeComponent,
    RoomFilterPipe,
    ParticipantsFilterPipe,
    UserFilterPipe,
    AllChallengeWonListComponent,
    AllChallengeLossListComponent,
    DareChallengeEarningComponent,
    DareDetailsComponent,
    WriteToUsComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChallengeProgressComponent,
    PlusBtnComponent,
    DiscussionComponent,
    LandingPageComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    RefundPolicyComponent,
    ChildPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgDatepickerModule,
    NgxTrimDirectiveModule,
    NgxPaginationModule,
    StorageServiceModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    LoadingBarHttpModule,
    LoadingBarRouterModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    UiSwitchModule,
    TagInputModule,
    ClickOutsideModule,
    // SocketIoModule.forRoot(socketConfig)
    SocialLoginModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA7KPu6H-pbDqLGNYsewhHF2Y2qH74X8RQ'
    }),
    NgxSmartModalModule.forRoot(),
    NgxMaterialTimepickerModule,
    NgxDaterangepickerMd.forRoot(),
    SelectDropDownModule,
    OwlModule,
    PickerModule,
    JwSocialButtonsModule,
    // ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    // ShareButtonsPopupModule,
    // ShareIconsModule
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireMessagingModule,

  ],
  providers: [
    UserService,
    CategoryService,
    DareService,
    ChallengeService,
    CoineService,
    ChatService,
    DiscussionService,
    NotificationService,
    NgxUiLoaderService,
    Title,
    ChatUtil,
    LocalStorageService,
    updateHeaderService,
    // MessagingService,
    {
      provide: AuthServiceConfig,
      useFactory: socialConfigs
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
