import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { map, catchError, tap, timeout } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DareService {
  backendUrl: any = environment.backendUrl;
  constructor(
    private http: HttpClient
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  };

  getAlertMessage(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getAlertMessage`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getActiveBets(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/activeDareList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
    // .pipe(map(this.extractData), timeout(500), catchError(e => {
    //   // do something on a timeout
    //   return of(null);
    // }));
  }

  getDareDetails(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/allPostDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getActiveBetsCategoryID(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/activeDareDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));

  }

  getDareDetailsUsingPlayId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/checkBetStatus`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getMemberListUsingPlayId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/userDareJoinList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  addPlayType(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/userCreateDare`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  deleteDare(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/deleteDare`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  updatePlayType(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/updateDare`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  dareParticipationUserDareJoinListList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/participatJoinList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  daresWonList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareWonList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  daresWonListUsingCategoryId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareWonDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  daresLossList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareLossList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  daresLossListUsingCategoryId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareLossDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  dareRequestList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareRequestList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getDareRequestListUsingCategoryId(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getRequestDareDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getFriendsListUsingLocation(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getNearbyUserList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  acceptDeclineRequestForDare(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/acceptDeclineRequest`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }


  claimDares(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/claimDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  // createClaimDares(data): Observable<any> {
  //   return this.http.post(`${this.backendUrl}/crateClaimUser`, data, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       "Access-Control-Allow-Origin": "*",
  //       "Auth-Key": data.api_key
  //     })
  //   })
  //     .pipe(map(this.extractData));
  // }

  createClaimDares(data): Observable<any> {
    var fd = new FormData();
    fd.append('user_id', data.user_id);
    fd.append('play_id', data.play_id);
    fd.append('group_id', data.group_id);
    fd.append('description', data.description);
    fd.append('image', data.image);
    fd.append('mobile', data.mobile);
    return this.http.post(`${this.backendUrl}/crateClaimUser`, fd, {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  claimList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getClaimListUser`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  changeClaimStatus(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/claimStatus`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getAllDares(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/allDareList`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }


  uploadProgress(data): Observable<any> {
    var fd = new FormData();
    fd.append('user_id', data.user_id);
    fd.append('play_id', data.play_id);
    fd.append('image', data.image);
    return this.http.post(`${this.backendUrl}/uploadVideo`, fd, {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  dareEarningList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareEarningList`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  dareEarningDetailsList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/myDareEarningDetail`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getDaresForUploadProgress(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/allPostDetail`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

}
