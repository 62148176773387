import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isShow: boolean = false;
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  message: any = {};
  apiCalled: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  showMsgCount: any = JSON.parse(localStorage.getItem('countAlert'));
  constructor(
    private dareService: DareService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    this.showMsgCount = JSON.parse(localStorage.getItem('countAlert'));
    if (this.showMsgCount == null) {
      localStorage.setItem('countAlert', JSON.stringify({ user_id: this.authToken.user_id, count: 0 }))
    }
    console.log('this.showMsgCount==', this.showMsgCount);
    if (this.showMsgCount != null && this.showMsgCount.count == 0 && Number(this.showMsgCount.user_id) == Number(this.authToken.user_id)) {
      // this.showAlertMessage();
    }
  }

  showHideBtn() {
    this.isShow != this.isShow
  }
  showAlertMessage() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.dareService.getAlertMessage(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.message = res.alertMessageList;
        if (res.alertMessageList.publish_type == "1") {
          Swal.fire('Alert!', this.message.message, 'success')
        }
        this.apiCalled = true;
        localStorage.setItem('countAlert', JSON.stringify({ user_id: this.authToken.user_id, count: 1 }))
        console.log('res----', this.message)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
