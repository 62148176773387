import { Component } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import * as $ from 'jquery';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
// import { MessagingService } from "./shared/messaging.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Dare IT';
  appTitle;
  message;
  status = 'ONLINE';
  isConnected = true;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private meta: Meta,
    private connectionService: ConnectionService,
    private toastr: ToastrService,
    // private messagingService: MessagingService
  ) {
    this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ name: 'twitter:site', content: '@alligatorio' });
    this.meta.addTag({ name: 'twitter:title', content: 'Front-end Web Development, Chewed Up' });
    this.meta.addTag({ name: 'twitter:description', content: 'Learn frontend web development...' });
    this.meta.addTag({ name: 'twitter:image', content: 'https://alligator.io/images/front-end-cover.png' });
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
        this.toastr.error('Internet not available, Cross check your internet connectivity and try again')
        // alert(this.status)
      }
    })
  }


  ngOnInit() {
    // const userId = 'akash001';
    // this.messagingService.requestPermission(userId)
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage

    this.titleService.setTitle(this.title);
    this.ngxService.start(); // start foreground loading with 'default' id
    // const author = this.meta.getTags(`name='twitter:card'`);
    // console.log(author[0].name);

    // Stop the foreground loading after 5s
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground loading with 'default' id
    }, 5000);


    // OR
    this.ngxService.startBackground('do-background-things');
    // Do something here...
    this.ngxService.stopBackground('do-background-things');
    this.appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.route.firstChild;
          if (child.snapshot.data['title']) {
            return this.appTitle + ' | ' + child.snapshot.data['title'];
          }
          return this.appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });


  }
}
