import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';
import { DareService } from '../../apiServices/dare/dare.service';

@Component({
  selector: 'app-earning',
  templateUrl: './earning.component.html',
  styleUrls: ['./earning.component.scss']
})
export class EarningComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  showWon: boolean = true;
  showLoss: boolean = true;
  show_to_won: any = '1';
  show_to_loss: any = '1';
  apiCalled: boolean = false;
  page_no: any = '1';
  type: any;
  categoryId: any;
  page_size: any = '9';
  totalEarning: any = 0;
  earningList: any = [];
  date_now: any;
  date_future: any;
  paramsValue: any;
  constructor(
    private challengeService: ChallengeService,
    private dareService: DareService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
    this.categoryId = this.paramsValue._value.categoryId;
    this.type = this.paramsValue._value.type
  }

  ngOnInit() {
    if (this.type == 'dare') {
      this.getDareEarningList();
    } else {
      this.getChallengeEarningList();
    }
  }

  getDareEarningList() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "category_id": this.categoryId,
      "dare_filter": "",
      "page_no": this.page_no,
      "page_size": this.page_size
    }
    this.dareService.dareEarningDetailsList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.apiCalled = true;
        this.totalEarning = 0;
        this.earningList = res.myDareDetail ? res.myDareDetail : [];
        for (let i = 0; i < this.earningList.length; i++) {
          this.totalEarning += Number(this.earningList[i].coin_point);
        }
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getChallengeEarningList() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "category_id": this.categoryId,
      "dare_filter": "2",
      "page_no": this.page_no,
      "page_size": this.page_size
    }
    this.challengeService.getChallengeEarnDetailsList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.apiCalled = true;
        this.totalEarning = 0;
        this.earningList = res.myDareDetail ? res.myDareDetail : [];
        for (let i = 0; i < this.earningList.length; i++) {
          this.totalEarning += Number(this.earningList[i].coin_point);
        }
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  gettimeDiff(createAt, timestamp) {
    this.date_future = new Date(timestamp);
    this.date_now = new Date(createAt);
    var seconds = Math.floor((this.date_future - (this.date_now)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    return days > 0 ? `${days} days` : hours > 0 ? `${hours} hours` : minutes > 0 ? `${minutes} minutes` : seconds > 0 ? `${seconds} seconds` : '';
  }
}
