import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
// import * as $ from 'jquery';
import * as moment from 'moment';

declare var $: any;
declare var owlCarousel;

@Component({
  selector: 'app-dare-details',
  templateUrl: './dare-details.component.html',
  styleUrls: ['./dare-details.component.scss']
})
export class DareDetailsComponent implements OnInit {
  // @HostBinding('class') defaultClass = 'owl-carousel';
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  @ViewChild('filePicker', { static: false }) myInputVariable: ElementRef;

  myForm: any = {};
  carouselData: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  apiCalledForMemberList: boolean = false;
  showError: boolean = false;
  apiCalled: boolean = false;
  getAllDaresList: any = [];
  pagination: any = {};
  memberList: any = [];
  participationMemberList: any = [];
  member: any = {};
  participationMember: any = {};
  dareDetails: any = {};
  status: any = '1';
  index: any = 0;
  paramsValue: any;
  memberType: any = '1';
  page_no: any = '1';
  page_size: any = '9';
  dare_filter: any = '1';
  config: any;
  date_now: any;
  date_future: any;
  isShowThumnail: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'Are you sure, you want to delete this dare?';
  popoverInactivateMessage: string = 'Are you sure, you want to deactivate this user?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  mySlideImages = [1, 2, 3].map((i) => `https://picsum.photos/640/480?image=${i}`);
  myCarouselImages = [1, 2, 3, 4, 5, 6].map((i) => `https://picsum.photos/640/480?image=${i}`);
  mySlideOptions: any = { items: 7, dots: true, nav: false };
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private dareService: DareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    // this.dareDetails = JSON.parse(localStorage.getItem('allDare'))
    // console.log('allDare===', this.dareDetails)
    // this.checkVideoAndImage(this.dareDetails.proof_list[0])
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    this.dareService.getDaresForUploadProgress(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.dareDetails = res.details;
        var endDate = moment(this.dareDetails.closed_date).format('YYYY-MM-DD') + " " + this.dareDetails.closed_time
        endDate = moment(new Date(endDate.replace(/-/g, "/"))).format('X');
        var currentDate = moment().format('X');
        if (Number(endDate) < Number(currentDate)) {
          this.dareDetails['isProgress'] = false
        } else {
          this.dareDetails['isProgress'] = true
        }
        //  const element = this.dareDetails[i];
        if (this.dareDetails.proof_list != 0) {
          this.checkVideoAndImage(this.dareDetails.proof_list[0], this.dareDetails, 'getAll')
        }
        this.apiCalled = true;
        // this.callowlCarousel();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  toggleVideo(event: any) {
    this.isShowThumnail = true;
    // this.carouselData.video_url = 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'
    this.videoplayer.nativeElement.play();
  }

  pageChanged(event) {
    this.page_no = event;
    this.ngOnInit();
  }

  gettimeDiff(createAt) {
    this.date_future = new Date();
    this.date_now = new Date(createAt);
    var seconds = Math.floor((this.date_future - (this.date_now)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    return days > 0 ? `${days} days` : hours > 0 ? `${hours} hours` : minutes > 0 ? `${minutes} minutes` : seconds > 0 ? `${seconds} seconds` : '';
  }

  // checkVideoAndImage(data, dare) {
  //   console.log('checkVideoAndImage====', dare)
  //   for (let i = 0; i < dare.proof_list.length; i++) {
  //     dare.proof_list[i]['isActive'] = false;
  //   }
  //   data['isActive'] = true;
  //   dare['showProofDetails'] = data
  // }
  checkVideoAndImage(data, dare, type) {
    // console.log('check details====', data, dare, type)
    if (type == 'getAll') {
      for (let i = 0; i < dare.proof_list.length; i++) {
        dare.proof_list[i]['isActive'] = false;
      }
      data['isActive'] = true;
      dare['showProofDetails'] = data
    } else {
      for (let i = 0; i < dare.proof_list.length; i++) {
        dare.proof_list[i]['isActive'] = false;
      }
      data['isActive'] = true;
      dare['showProofDetails'] = data
      this.isShowThumnail = false;
    }
    // console.log('checkVideoAndImage====', data)
    // this.carouselData = data;
  }

  getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }

  getDareMemberList(play_id, proof_list) {
    this.memberList = proof_list
    console.log('check member details====', this.memberList)
    this.apiCalledForMemberList = true;
    this.member = this.memberList[0];
    // this.memberList = [];
    // var reqObj = {
    //   "user_id": this.authToken.user_id,
    //   "api_key": this.authToken.api_key,
    //   "play_id": play_id
    // }
    // this.dareService.getMemberListUsingPlayId(reqObj).subscribe(res => {
    //   if (res.responseCode == 200) {
    //     this.loading = this.showError = false;
    //     this.memberList = res.userList
    //     if (res && res.userList != 0) {
    //       this.member = this.memberList[0];
    //     }
    //     this.apiCalledForMemberList = true;
    //   } else if (res.responseCode == 402) {
    //     this.toastr.error('Your session has expired, Please login again.');
    //     this.router.navigate(['/login']);
    //   } else {
    //     this.toastr.error(res.successMessage);
    //     this.loading = false;
    //   }
    // }, (err) => {
    //   console.log(err);
    // });
  }

  next() {
    var index = this.index + 1;
    if (index <= this.memberList.length - 1) {
      this.index = index;
      this.member = this.memberList[this.index];
    }
  }

  previews() {
    var i = this.index - 1;
    this.index = i == -1 ? 0 : i;
    this.member = this.memberList[this.index]
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    console.log(files)
    if (files.length === 0)
      return;
    const fsize = files[0].size;
    const file = Math.round((fsize / 1024 / 1024));
    var mimeType = files[0].type;
    console.log('call size====', fsize, file, mimeType)
    if (!(file <= 100)) {
      return this.toastr.warning("File too Big, please select a file less than 100mb")
    }
    var reader = new FileReader();
    // this.imagePath = files;
    this.myForm.image = files[0];
    console.log('image===', this.myForm.image)
    reader.readAsDataURL(files[0]);
  }

  openModal(dare) {
    var startDate = moment(dare.start_date).format('YYYY-MM-DD') + " " + dare.start_time
    var endDate = moment(dare.closed_date).format('YYYY-MM-DD') + " " + dare.closed_time
    // console.log('startDate===1', startDate);
    startDate = moment(new Date(startDate.replace(/-/g, "/"))).format('X');
    endDate = moment(new Date(endDate.replace(/-/g, "/"))).format('X');
    var currentDate = moment().format('X');
    if (Number(currentDate) > Number(startDate) && Number(endDate) < Number(currentDate)) {
      this.toastr.info('Dare has already closed.')
    } else {
      if (startDate <= currentDate) {
        if (dare.isProgress) {
          this.myForm.play_id = dare.play_id
          this.ngxSmartModalService.open('myModal');
        } else {
          this.toastr.info('Dare already closed.')
        }
      } else {
        this.toastr.info('This will enable after the dare start.')
      }
    }
  }

  submitProgress() {
    this.loading = true;
    console.log('this.myForm===', this.myForm)
    if (!this.myForm.image) {
      this.toastr.error('Please select progress report first.');
      this.loading = this.showError = false;
    } else {
      var reqObj = {
        "user_id": this.authToken.user_id,
        "api_key": this.authToken.api_key,
        "play_id": this.myForm.play_id,
        "image": this.myForm.image
      }
      this.dareService.uploadProgress(reqObj).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.ngxSmartModalService.close('myModal');
          this.myInputVariable.nativeElement.value = '';
          this.ngOnInit();
        } else if (res.responseCode == 402) {

          this.toastr.error('Your session has expired, Please login again.');
          this.router.navigate(['/login']);
        } else {
          this.toastr.error(res.successMessage);
          this.loading = this.showError = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  closeProgesspop() {
    this.ngxSmartModalService.close('myModal');
  }

  callowlCarousel() {
    // $('.owl-carousel').owlCarousel({
    //   loop: true,
    //   dots: true,
    //   margin: 10,
    //   nav: true,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     600: {
    //       items: 3
    //     },
    //     1000: {
    //       items: 5
    //     }
    //   }
    // })
  }
  // ngAfterViewInit() {
  //   setTimeout(function () {
  //   }, 300);
  // }
}
