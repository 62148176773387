import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../apiServices/dare/dare.service';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';

@Component({
  selector: 'app-dashboard-close-bets',
  templateUrl: './dashboard-close-bets.component.html',
  styleUrls: ['./dashboard-close-bets.component.scss']
})
export class DashboardCloseBetsComponent implements OnInit {

  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  challengeRequestList: any = [];
  dareRequestList: any = [];
  show_to: any = '1';
  showDare: boolean = true;
  showChallenge: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private dareService: DareService,
    private challengeService: ChallengeService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    this.getDareRequest();
    // this.getChallengeRequest();
  }

  getDareRequest() {
    this.showDare = true;
    this.showChallenge = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "show_to": this.show_to,
      "page_no": "1",
      "page_size": "3"
    }
    this.dareService.dareRequestList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.dareRequestList = res.myDareList;
        // console.log('res--==--dare---===----', this.dareRequestList)
      } else if (res.responseCode == 402) {
        // this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getChallengeRequest() {
    this.showDare = false;
    this.showChallenge = true;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": "1",
      "page_size": "3"
    }
    this.challengeService.getChallengesRequestList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengeRequestList = res.myChallengeList;
        // console.log('res--==--challenge---===----', this.challengeRequestList)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
