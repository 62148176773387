import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../helper/localStorage.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthServiceService } from '../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatepickerOptions } from 'ng2-datepicker';
import * as moment from 'moment';
import { CategoryService } from '../../apiServices/category/category.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss']
})
export class NewCategoryComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  myForm: any = {};
  categoryList: any;
  showModal: boolean = false;
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  currentBookingDate: any = moment().subtract(1, 'days');
  date: any = new Date(this.currentBookingDate);
  startDateOptions: DatepickerOptions = {
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(this.currentBookingDate), // Minimal selectable date
    maxDate: new Date(2033, 1, 1), // Maximal selectable date
    barTitleIfEmpty: 'Click to select a start date',
    placeholder: 'Click to select a start date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private categoryService: CategoryService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    console.log('this---', this.showModal)
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.categoryService.getCategoryList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.categoryList = res.categoryList;
        console.log('res----', this.categoryList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  openModal() {
    this.myForm = {};
    // this.showModal = !this.showModal;
    this.showError = false;
    this.ngxSmartModalService.open('myModal');
  }

  onSubmit(valid) {
    console.log('valid===', valid)
    this.showError = true;
    this.showModal = !this.showModal;
    if (valid) {
      this.loading = true;
      this.myForm.user_id = this.authToken.user_id
      this.myForm["api_key"] = this.authToken.api_key
      this.categoryService.addCategory(this.myForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.ngxSmartModalService.close('myModal');
          this.showModal = false;
          // $("#viewMember").fadeOut();
          // this.showModal = !this.showModal;
          this.ngOnInit();
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

}
