import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeService } from '../../../apiServices/challenge/challenge.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ChatService } from '../../../apiServices/chat/chat.service';
import { updateHeaderService } from '../../../_service'

@Component({
  selector: 'app-challenges-list',
  templateUrl: './challenges-list.component.html',
  styleUrls: ['./challenges-list.component.scss']
})
export class ChallengesListComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  callApi: boolean = false;
  challengesList: any = [];
  memberList: any = [];
  participationMemberList: any = [];
  member: any = {};
  participationMember: any = {};
  status: any = '1';
  index: any = 0;
  paramsValue: any;
  memberType: any = '1';
  page_no: any = '1';
  page_size: any = '9';
  apiCalled: boolean = false;
  pagination: any = {};
  challenge_filter: any = ''
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'Are you sure, you want to delete this challenge?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  constructor(
    private challengeService: ChallengeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private chatService: ChatService,
    private header: updateHeaderService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": this.page_no,
      "challenge_filter": this.challenge_filter,
      "page_size": this.page_size,
      "category_id": this.paramsValue._value.categoryId
    }
    this.challengeService.getActiveChallengestListUsingCategoryId(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengesList = res.myChallengeDetail;
        this.apiCalled = true;
        // if (this.challenge_filter == '1') {
        for (let i = 0; i < this.challengesList.length; i++) {
          this.challengesList[i]['isEdit'] = true;
          // var startDate = moment(this.challengesList[i].start_date).format('YYYY-MM-DD');
          // var currentDate = moment().format('YYYY-MM-DD');
          // if (startDate <= currentDate) {
          //   this.challengesList[i]['isClaim'] = true;
          //   this.challengesList[i]['isEdit'] = true;
          // } else {
          //   this.challengesList[i]['isClaim'] = false;
          //   this.challengesList[i]['isEdit'] = false;
          // }
          if (this.challengesList[i].user_id == this.authToken.user_id) {
            this.challengesList[i]['isDelete'] = true;
          } else {
            this.challengesList[i]['isDelete'] = false;
          }
          // console.log('this.challengesList===', this.challengesList)
          //  const element = this.challengesList[i];
          var claimActiveChallenge = moment(new Date(this.challengesList[i].closed_date_time)).format('YYYY-MM-DD HH:mm:ss');
          var claimActiveChallengeAfterAddClaimHours = moment(new Date(this.challengesList[i].closed_date_time)).add(this.challengesList[i].claim_hour, 'hours').format('YYYY-MM-DD HH:mm:ss');
          var claimStatusCheck = moment(new Date(this.challengesList[i].closed_date_time)).add(this.challengesList[i].claim_hour + 4, 'hours').format('YYYY-MM-DD HH:mm:ss');
          var startDate = moment(new Date(this.challengesList[i].closed_date)).format('X');
          var currentDate = moment().format('X');
          if (startDate <= currentDate) {
            // this.challengesList[i]['isClaim'] = true;
            this.challengesList[i]['isProgress'] = true;
          } else {
            // this.challengesList[i]['isClaim'] = false;
            this.challengesList[i]['isProgress'] = false;
          }
          if (Number(moment(new Date(claimActiveChallenge)).format('X')) < Number(currentDate)) {
            this.challengesList[i]['isClaim'] = true;
            this.challengesList[i]['isClaimStatusBtn'] = true;
          } else {
            this.challengesList[i]['isClaim'] = false;
            this.challengesList[i]['isClaimStatusBtn'] = false;
          }
          if (Number(moment(new Date(claimActiveChallengeAfterAddClaimHours)).format('X')) < Number(currentDate)) {
            this.challengesList[i]['isClaim'] = false;
          }
          //console.log('condition====1', Number(moment(new Date(claimActiveChallengeAfterAddClaimHours)).format('X')) < Number(currentDate))
          //console.log('timestamp====', Number(moment(new Date(claimActiveChallengeAfterAddClaimHours)).format('X')) < Number(currentDate))
          if (moment(new Date(claimStatusCheck)).format('X') < currentDate) {
            this.challengesList[i]['isClaimStatusBtn'] = false;
          }
          this.pagination = res.pagination;
          // }
        }
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  pageChanged(event) {
    this.page_no = event;
    this.ngOnInit();
  }

  getDareMemberList(play_id) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": play_id
    }
    this.challengeService.getMemberListUsingPlayId(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.memberList = res.userList
        if (res && res.userList != 0) {
          this.member = this.memberList[0];
        }
        this.callApi = !this.callApi;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  checkStatus(challenge, btnType) {
    var startDate = moment(challenge.start_date).format('YYYY-MM-DD') + " " + challenge.start_time
    startDate = moment(new Date(startDate)).format('X');
    var currentDate = moment().format('X');
    var claimStatusCheck = moment(new Date(challenge.closed_date_time)).add((challenge.claim_hour + 4), 'hours').format('YYYY-MM-DD HH:mm:ss');
    claimStatusCheck = moment(new Date(claimStatusCheck)).format('X');
    console.log('startDate===', startDate)
    console.log('currentDate===', currentDate)
    if (btnType == 'isClaim') {
      // if (Number(startDate) < Number(currentDate)) {
      //   if (Number(claimStatusCheck) < Number(currentDate)) {
      //     this.toastr.info('Challenge time ended, you cannot create a claim now.');
      //   } else {
      //     if (challenge.claim_status == 1) {
      //       this.toastr.success('You have already created a claim.');
      //     } else {
      //       this.router.navigate(['/claim_challenge/' + challenge.play_id]);
      //     }
      //   }
      // } else {
      //   this.toastr.info('This will enable after the challenge start.')
      // }
      if (Number(claimStatusCheck) < Number(currentDate)) {
        this.toastr.info('Dare time ended, you cannot create a claim now.');
      } else {
        console.log('check timestamp====', Number(startDate) < Number(currentDate), startDate, currentDate)
        if (Number(startDate) < Number(currentDate)) {
          this.storeData(challenge);
          if (challenge.claim_status == 1) {
            this.toastr.success('You have already created a claim.');
          } else {
            this.router.navigate(['/claim_challenge/' + challenge.play_id]);
          }
        } else {
          this.toastr.info('This will enable after the challenge start.')
        }
      }
    } else if (btnType == 'isClaimStatusBtn') {
      if (Number(startDate) < Number(currentDate)) {
        this.router.navigate([`/view_claim/${challenge.play_id}`]);
      } else {
        this.toastr.info('This will enable after the challenge start.')
      }
    }
  }
  createClaim(challenge) {
    if (challenge.claim_status == 1) {
      this.toastr.success('You have already created a claim.');
    } else {
      this.router.navigate(['/claim_challenge/' + challenge.play_id]);
    }
  }

  delete(challenge) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": challenge.play_id
    }
    this.challengeService.deleteChallenge(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.toastr.success(res.successMessage);
        this.deleteGroup(challenge.play_id);
        this.header.checkFunction(true);
        this.ngOnInit();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  deleteGroup(dareId) {
    this.chatService.deleteGroup(dareId).subscribe(res => {
      // console.log(res)
    }, (err) => {
      console.log(err);
    });
  }

  storeData(data) {
    localStorage.setItem('challenge', JSON.stringify(data))
  }
}
