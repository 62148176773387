import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { EventEmitter } from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class updateHeaderService {
    $isCalledService = new EventEmitter()
    // private totalItems: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    // getCoin() {
    //     return this.totalItems.asObservable();
    // }

    // updateCoin(items: number) {
    //     this.totalItems.next(items);
    // }
    checkFunction(isCall) {
        // console.log('checking working or not---,,,')
        this.$isCalledService.emit(isCall)
    }
}