import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
import * as moment from 'moment';
import { DatepickerOptions } from 'ng2-datepicker';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  private base64textString: String = "";
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/uploads/';
  loading: boolean = false;
  showError: boolean = false;
  submitted: boolean = false;
  profile: any = {};
  status: any = '1';
  isShowPassword = false;
  profileImage: any = '../../assets/images/user_avatar_thumbnail.png';
  memberType: any = '1';
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  minDate: any = moment().subtract('years', 18);
  maxYear: any = moment().subtract('years', 15).year();
  maxDate: any = moment().subtract('years', 15);
  date: any = new Date(this.minDate);
  qualificationList: any = [];
  dateOptions: DatepickerOptions = {
    maxYear: this.maxYear,
    // maxYear: 2030,
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    // minDate: new Date(this.minDate), // Minimal selectable date
    maxDate: new Date(this.maxDate), // Maximal selectable date
    barTitleIfEmpty: 'Select DOB',
    placeholder: 'Select DOB', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 3
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.userService.getProfile(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.profile = res.userDetails[0];
        console.log('profile====', this.profile)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });

  }


  toggleFieldTextType() {
    this.isShowPassword = !this.isShowPassword;
  }


  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  onSubmit(valid) {
    this.submitted = true;
    this.showError = true;
    this.myForm['user_id'] = this.profile.user_id;
    this.myForm['api_key'] = this.authToken.api_key
    if (valid) {
      this.loading = true;
      if (this.myForm.old_password === this.myForm.new_password) {
        this.loading = false;
        this.toastr.error('New password should not be same as old password.')
      } else
        if (this.myForm.new_password === this.myForm.confirmPassword) {
          console.log('check req====', this.myForm)
          this.userService.changePassword(this.myForm).subscribe(res => {
            if (res.responseCode == 200) {
              this.loading = false;
              this.submitted = false;
              this.showError = false;
              this.myForm = {}
              // this.router.navigate(['/login']);
              this.toastr.success(res.responseMessage);
            } else {
              this.toastr.error(res.responseMessage);
              this.loading = false;
              this.submitted = false;
              this.showError = false;
            }
          }, (err) => {
            console.log(err);
          })
        } else {
          // this.msgError = 'Confirm password should be same as new password'
          this.toastr.error('Confirm password should be same as new password');
        }
    }
  }

}
