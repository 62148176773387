// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-forgot-password',
//   templateUrl: './forgot-password.component.html',
//   styleUrls: ['./forgot-password.component.scss']
// })
// export class ForgotPasswordComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
// import { AuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import * as $ from 'jquery';
// import {} from "googlemaps";
declare module 'googlemaps';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('gmap', { static: false }) gmapElement: any;
  map: google.maps.Map;

  isTracking = false;
  address: string = '';
  currentLat: any;
  currentLong: any;

  myForm: any = {};
  loading: boolean = false;
  showError: boolean = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    // private socialAuthService: AuthService
  ) { }

  ngOnInit() {
    // this.authService.logout();
    // this.findMe();
  }

  onSubmit(valid) {
    this.showError = true;
    if (valid) {
      this.loading = true;
      console.log('check request---', this.myForm)
      // this.myForm.latitude = this.currentLat;
      // this.myForm.longitude = this.currentLong;
      // this.myForm.location = this.address;
      // this.myForm.deviceType = this.myForm.deviceToken = 'Web'
      this.userService.forgotPassword(this.myForm).subscribe(res => {
        if (res.responseCode == 200) {
          res['mobile'] = this.myForm.mobile
          localStorage.setItem('forgotResponse', JSON.stringify(res));
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.router.navigate(['/verifyOtp']);
          localStorage.setItem('whichScreen', 'forgot');
        } else {
          this.toastr.error(res.successMessage);
          this.loading = this.showError = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

}
