import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import * as $ from 'jquery';

import { TopNavbarComponent } from './components/top-navbar/top-navbar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ChallengesComponent } from './components/challengeModule/challenges/challenges.component';
import { MydaresComponent } from './components/dareModule/mydares/mydares.component';
import { ChatsComponent } from './components/chats/chats.component';
import { AddNewBetsComponent } from './components/dareModule/add-new-bets/add-new-bets.component';
import { ViewDareComponent } from './components/dareModule/view-dare/view-dare.component';
import { UserChatComponent } from './components/user-chat/user-chat.component';
import { ClaimDareComponent } from './components/dareModule/claim-dare/claim-dare.component';
import { ViewClaimComponent } from './components/view-claim/view-claim.component';
import { ActiveBetsComponent } from './components/active-bets/active-bets.component';
import { LoginComponent } from './components/authModule/login/login.component';
import { RegistrationComponent } from './components/authModule/registration/registration.component';
import { OtpVerificationComponent } from './components/authModule/otp-verification/otp-verification.component';
// import { BetsRequestComponent } from './components/bets-request/bets-request.component';
import { DaresWonComponent } from './components/dareModule/dares-won/dares-won.component';
import { CoinStoreComponent } from './components/coin-store/coin-store.component';
import { EarningComponent } from './components/earning/earning.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ProfileComponent } from './components/setting/profile/profile.component';
import { ProfileEditComponent } from './components/setting/profile-edit/profile-edit.component';
import { BankAccountComponent } from './components/setting/bank-account/bank-account.component';
import { AllDaresComponent } from './components/dareModule/all-dares/all-dares.component';
import { DareOfTheWeekComponent } from './components/dareModule/dare-of-the-week/dare-of-the-week.component';
import { CoinsWalletComponent } from './components/coins-wallet/coins-wallet.component';
import { AboutUSComponent } from './components/setting/about-us/about-us.component';
import { NewCategoryComponent } from './components/new-category/new-category.component';
import { DareRequestListComponent } from './components/dareModule/dare-request-list/dare-request-list.component';
import { ChallengeRequestListComponent } from './components/challengeModule/challenge-request-list/challenge-request-list.component';
import { AllRequestListComponent } from './components/all-request-list/all-request-list.component';
import { AllDareWonLossRequestListComponent } from './components/all-dare-won-loss-request-list/all-dare-won-loss-request-list.component';
import { AllDareWonListComponent } from './components/all-dare-won-list/all-dare-won-list.component';
import { AllDareLossListComponent } from './components/all-dare-loss-list/all-dare-loss-list.component';
import { AllChallengeWonLostRequestListComponent } from './components/all-challenge-won-lost-request-list/all-challenge-won-lost-request-list.component';
import { ChallengesListComponent } from './components/challengeModule/challenges-list/challenges-list.component';
import { AddChallengeComponent } from './components/challengeModule/add-challenge/add-challenge.component';
import { ViewChallengeComponent } from './components/challengeModule/view-challenge/view-challenge.component';
import { EditChallengeComponent } from './components/challengeModule/edit-challenge/edit-challenge.component';
import { NewAllDaresComponent } from './components/new-all-dares/new-all-dares.component';
import { ClaimChallengeComponent } from './components/challengeModule/claim-challenge/claim-challenge.component';
import { AllChallengeWonListComponent } from './components/all-challenge-won-list/all-challenge-won-list.component';
import { AllChallengeLossListComponent } from './components/all-challenge-loss-list/all-challenge-loss-list.component';
import { DareChallengeEarningComponent } from './components/dare-challenge-earning/dare-challenge-earning.component';
import { DareDetailsComponent } from './components/dareModule/dare-details/dare-details.component';
import { WriteToUsComponent } from './components/write-to-us/write-to-us.component';

import { ChangePasswordComponent } from './components/setting/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/authModule/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/authModule/reset-password/reset-password.component';
import { ChallengeProgressComponent } from './components/challenge-progress/challenge-progress.component';

import { DareListUsingCategoryComponent } from './components/dareModule/dare-list-using-category/dare-list-using-category.component';
import { EditBetComponent } from './components/dareModule/edit-bet/edit-bet.component';

import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';
import { ChildPolicyComponent } from './components/pages/child-policy/child-policy.component';



import { AuthServiceService } from './auth-service.service';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: LandingPageComponent, data: { title: 'Home' } },
  { path: 'privacy_policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
  { path: 'terms_conditions', component: TermsConditionsComponent, data: { title: 'Terms & Conditions' } },
  { path: 'refund-policy', component: RefundPolicyComponent, data: { title: 'Refund Policy' } },
  { path: 'child-policy', component: ChildPolicyComponent, data: { title: 'Child Policy' } },


  { path: 'login', component: LoginComponent, data: { title: 'LOGIN' } },
  { path: 'registration', component: RegistrationComponent, data: { title: 'REGISTRATION' } },
  { path: 'verifyOtp', component: OtpVerificationComponent, data: { title: 'OTP VERIFICATION' } },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { title: 'USER DASHBOARD' } },
  { path: 'change_password', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { title: 'CHANGE PASSWORD' } },
  { path: 'forgot_password', component: ForgotPasswordComponent, data: { title: 'FORGOT PASSWORD' } },
  { path: 'reset_password', component: ResetPasswordComponent, data: { title: 'RESET PASSWORD' } },
  { path: 'challenges', component: ChallengesComponent, canActivate: [AuthGuard] },
  { path: 'add_new_challenge', component: AddChallengeComponent, canActivate: [AuthGuard] },
  { path: 'view_challenge_details/:pid', component: ViewChallengeComponent, canActivate: [AuthGuard] },
  { path: 'editChallenge/:pid', component: EditChallengeComponent, canActivate: [AuthGuard] },
  { path: 'challenge_detail/:pid', component: ChallengeProgressComponent, canActivate: [AuthGuard] },
  { path: 'claim_challenge/:pid', component: ClaimChallengeComponent, canActivate: [AuthGuard] },
  { path: 'myDares', component: MydaresComponent, canActivate: [AuthGuard] },
  { path: 'chats', component: ChatsComponent, canActivate: [AuthGuard] },
  { path: ':pid/chats', component: ChatsComponent, canActivate: [AuthGuard] },
  { path: 'add_new_dare', component: AddNewBetsComponent, canActivate: [AuthGuard] },
  { path: 'dare/:categoryId/:dareType', component: DareListUsingCategoryComponent, canActivate: [AuthGuard] },
  { path: 'view_dare_details/:pid', component: ViewDareComponent, canActivate: [AuthGuard] },
  { path: 'dare_detail/:pid', component: DareDetailsComponent, canActivate: [AuthGuard] },
  { path: 'claim_dare/:pid', component: ClaimDareComponent, canActivate: [AuthGuard] },
  { path: 'view_claim/:pid', component: ViewClaimComponent, canActivate: [AuthGuard] },
  { path: 'editDare/:pid', component: EditBetComponent, canActivate: [AuthGuard] },
  { path: 'discussion/:pid', component: DiscussionComponent, canActivate: [AuthGuard] },
  { path: 'userChat', component: UserChatComponent, canActivate: [AuthGuard] },
  { path: 'activeBets', component: ActiveBetsComponent, canActivate: [AuthGuard] },
  // { path: 'betsRequest', component: BetsRequestComponent, canActivate: [AuthGuard] },
  { path: 'daresWon', component: DaresWonComponent, canActivate: [AuthGuard] },
  { path: 'coinStore', component: CoinStoreComponent, canActivate: [AuthGuard] },
  { path: ':categoryId/:type/earning', component: EarningComponent, canActivate: [AuthGuard] },
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'new_category', component: NewCategoryComponent, canActivate: [AuthGuard] },
  { path: 'profileEdit', component: ProfileEditComponent, canActivate: [AuthGuard] },
  { path: 'bankAccount', component: BankAccountComponent, canActivate: [AuthGuard] },
  { path: 'about_us', component: AboutUSComponent, canActivate: [AuthGuard] },
  // { path: 'allDares', component: AllDaresComponent, canActivate: [AuthGuard] },
  { path: 'allDares', component: NewAllDaresComponent, canActivate: [AuthGuard] },
  { path: 'all_requests', component: AllRequestListComponent, canActivate: [AuthGuard] },
  { path: 'all_dare_won_lost_list', component: AllDareWonLossRequestListComponent, canActivate: [AuthGuard] },
  { path: 'all_challenges_won_lost_requests', component: AllChallengeWonLostRequestListComponent, canActivate: [AuthGuard] },
  { path: ':categoryId/dare_request', component: DareRequestListComponent, canActivate: [AuthGuard] },
  { path: ':categoryId/challenge_request', component: ChallengeRequestListComponent, canActivate: [AuthGuard] },
  { path: ':categoryId/dare_won', component: AllDareWonListComponent, canActivate: [AuthGuard] },
  { path: ':categoryId/dare_loss', component: AllDareLossListComponent, canActivate: [AuthGuard] },

  { path: ':categoryId/challenge_won', component: AllChallengeWonListComponent, canActivate: [AuthGuard] },
  { path: ':categoryId/challenge_loss', component: AllChallengeLossListComponent, canActivate: [AuthGuard] },
  { path: ':categoryId/challenges', component: ChallengesListComponent, canActivate: [AuthGuard] },
  { path: 'BestOfTheWeek', component: DareOfTheWeekComponent, canActivate: [AuthGuard] },
  { path: 'coinsWallet', component: CoinsWalletComponent, canActivate: [AuthGuard] },
  { path: 'dare_challenge_earn_list', component: DareChallengeEarningComponent, canActivate: [AuthGuard] },
  { path: 'newAllDares', component: NewAllDaresComponent, canActivate: [AuthGuard] },
  { path: 'writetous', component: WriteToUsComponent, canActivate: [AuthGuard] },
  { path: '**', component: LandingPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
