import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../apiServices/dare/dare.service';
import { UserService } from '../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';

@Component({
  selector: 'app-dare-challenge-earning',
  templateUrl: './dare-challenge-earning.component.html',
  styleUrls: ['./dare-challenge-earning.component.scss']
})
export class DareChallengeEarningComponent implements OnInit {

  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  dareWonList: any = [];
  dareLossList: any = [];
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  challenge_page_no: any = '1';
  dare_page_no: any = '1';
  page_size: any = '9';
  show_to_earn: any = '1';
  earningList: any = [];
  challengeEarningList: any = [];
  pagination: any = {};
  darePagination: any = {};
  apiCalled: boolean = false;
  showForDare: boolean = true;
  showForChallenge: boolean = false;
  constructor(
    private apiService: UserService,
    private dareService: DareService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private challengeService: ChallengeService,
  ) { }

  ngOnInit() {
    this.getEarningList();
  }

  getEarningList() {
    this.apiCalled = false;
    this.showForChallenge = false;
    this.showForDare = true;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": this.dare_page_no,
      "show_to": this.show_to_earn,
      "page_size": this.page_size

    }
    this.dareService.dareEarningList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.earningList = res.myEarningList ? res.myEarningList : [];
        this.darePagination = res.pagination;
        this.apiCalled = true;
      } else if (res.responseCode == 402) {
        // this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getChallengeEarningList() {
    this.apiCalled = false;
    this.showForDare = false;
    this.showForChallenge = true;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": this.challenge_page_no,
      "show_to": "2",
      "page_size": this.page_size

    }
    this.challengeService.getChallengeEarnList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengeEarningList = res.myEarningList ? res.myEarningList : [];
        this.pagination = res.pagination;
        this.apiCalled = true;
        console.log('this.earningList===', this.challengeEarningList)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  darePageChanged(event) {
    this.dare_page_no = event;
    this.ngOnInit();
  }
  challengePageChanged(event) {
    this.challenge_page_no = event;
    this.getChallengeEarningList();
  }
}
