import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../../apiServices/dare/dare.service';
import { ChallengeService } from '../../../apiServices/challenge/challenge.service';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from '../../../apiServices/chat/chat.service';
import { LocalStorageService } from '../../helper/localStorage.service';
import { updateHeaderService } from '../../../_service'

@Component({
  selector: 'app-dare-request-list',
  templateUrl: './dare-request-list.component.html',
  styleUrls: ['./dare-request-list.component.scss']
})
export class DareRequestListComponent implements OnInit {

  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  challengeRequestList: any = [];
  dareRequestList: any = [];
  pagination: any = {};
  apiCalled: boolean = false;
  page_no: any = '1';
  page_size: any = '9';
  show_to: any = '1';
  paramsValue: any;
  dare_filter: any = '1'
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  socketConnected = false;
  socket: any;
  constructor(
    private dareService: DareService,
    private challengeService: ChallengeService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private socketService: ChatService,
    private header: updateHeaderService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.getDareRequest();
    this.socket = this.socketService.getSocket();
    this.initIoConnection();
  }
  private initIoConnection(): void {
    if (this.socket && this.socket.connected) {
      this.socketConnected = true;
    } else {
      this.socketService.initSocket();
    }
  }
  getDareRequest() {
    this.apiCalled = false;
    this.show_to = localStorage.getItem('dare_show_to');
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "show_to": this.show_to,
      "dare_filter": "2",
      "category_id": this.paramsValue._value.categoryId,
      "page_no": this.page_no,
      "page_size": this.page_size
    }
    this.dareService.getDareRequestListUsingCategoryId(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.dareRequestList = res.myDareDetail;
        for (let i = 0; i < this.dareRequestList.length; i++) {
          this.dareRequestList[i]['user_left'] = Number(this.dareRequestList[i].user_limit) - Number(this.dareRequestList[i].member_join);
        }
        this.pagination = res.pagination
        this.apiCalled = true;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  acceptDeclineRequest(dare, status) {
    this.loading = true;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "friend_id": dare.user_id,
      "api_key": this.authToken.api_key,
      "play_id": dare.play_id,
      "status": status
    }
    this.dareService.acceptDeclineRequestForDare(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        // this.challengeRequestList = res.myChallengeDetail;
        this.toastr.success(res.successMessage);
        if (status == 1) {
          this.addParticipant(dare.user_id, dare.play_id, this.authToken.user_id)
        }
        // this.header.checkFunction(true);
        this.getDareRequest();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  pageChanged(event) {
    this.page_no = event;
    this.ngOnInit();
  }

  public init(): void {
    console.log("init");
    console.log(this.socketService.getSocket())
    this.socketService.init({ userId: this.authToken.user_id, userName: this.authToken.user_name });
    //this.getRoomList();
  }
  addParticipant(userId, dareId, friend_id) {
    this.socket = this.socketService.getSocket();
    console.log('check socket===', this.socket)
    if (this.socket.disconnected) {
      this.init();
      return;
    }
    var participants = [];
    participants.push(friend_id)
    // this.filtered_users.forEach((u)=>{
    //   if(u.checked)  participants.push(u._id);
    // });
    var add_participant_req = {
      userId: userId,
      // roomId : this.activeRoom._id,
      dareId: dareId,
      participants
    };
    if (participants.length == 0) {
      this.toastr.info("Please select at list one participant");
      return;
    }
    console.log('check req====', add_participant_req)
    // this.groupUpdating = false;
    // this.ngxSmartModalService.close('myModal');
    this.socketService.addParticipants(add_participant_req);
  }
}
