import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../helper/localStorage.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatepickerOptions } from 'ng2-datepicker';
import * as moment from 'moment';
import { ChallengeService } from '../../../apiServices/challenge/challenge.service';
import { CategoryService } from '../../../apiServices/category/category.service';
declare module 'googlemaps';
import * as $ from 'jquery';
import { NgxSmartModalService } from 'ngx-smart-modal';


@Component({
  selector: 'app-edit-challenge',
  templateUrl: './edit-challenge.component.html',
  styleUrls: ['./edit-challenge.component.scss']
})
export class EditChallengeComponent implements OnInit {
  @ViewChild('gmap', { static: false }) gmapElement: any;
  map: google.maps.Map;

  isTracking = false;
  address: string = '';
  currentLat: any;
  currentLong: any;
  minTime: any = '12:00 am';
  minTargetTime: any = '12:00 am';
  myForm: any = {
    show_to_all: true,
    tag_id: []
  };
  tags: any = [];
  showCategoryError: boolean = false;
  categoryForm: any = {};
  categoryList: any = [];
  usersList: any = [];
  selectUndefinedValue: any;
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  currentBookingDate: any = moment().add(30, 'minutes').subtract(1, 'days');
  date: any = new Date(this.currentBookingDate);
  showModal: boolean = false;
  startDateOptions: DatepickerOptions = {
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(this.currentBookingDate), // Minimal selectable date
    maxDate: new Date(2033, 1, 1), // Maximal selectable date
    barTitleIfEmpty: 'Start Date',
    placeholder: 'Start Date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  targetDateOptions: DatepickerOptions = {
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(this.currentBookingDate), // Minimal selectable date
    maxDate: new Date(2033, 1, 1), // Maximal selectable date
    barTitleIfEmpty: 'Target Date',
    placeholder: 'Target Date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  show_to_all: boolean = true;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private challengeService: ChallengeService,
    private categoryService: CategoryService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    this.findMe();
    this.categoryListFunction();
    this.getFriendList();
    var challengeDetails = JSON.parse(localStorage.getItem('challenge'));
    // console.log('---this.myForm---', JSON.stringify(challengeDetails))
    this.myForm.title = challengeDetails.play_name
    this.myForm.challenge_price = challengeDetails.dare_price
    this.myForm.challenge_description = challengeDetails.play_decription
    this.myForm.challenge_close_limit = challengeDetails.time_limit
    this.myForm.start_date = new Date(challengeDetails.start_date)
    this.myForm.start_time = challengeDetails.start_time
    this.myForm.target_date = new Date(challengeDetails.closed_date)
    this.myForm.target_time = challengeDetails.closed_time
    this.myForm.category_id = challengeDetails.category_id
    this.myForm.play_id = challengeDetails.play_id
    this.convertTime12to24(challengeDetails.start_time)
    this.minTime = this.myForm.start_time
    this.minTargetTime = this.myForm.target_time
    this.myForm.show_to_all = challengeDetails.show_to_all == "0" ? false : true;
    this.show_to_all = challengeDetails.show_to_all == "0" ? false : true;
    console.log('challengeDetails.show_to_all===', challengeDetails.show_to_all)
    if (challengeDetails && challengeDetails.tag_list) {
      this.tags = challengeDetails.tag_list.split(",");
    }
    // this.checkTimeValidationForTarget();
    // this.checkTimeValidation();
  };

  categoryListFunction() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.categoryService.getCategoryList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.categoryList = res.categoryList;
        console.log('res----', this.categoryList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  showPosition(position) {
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;
  }

  openModal(valid) {
    this.showError = true;
    if (valid) {
      // this.loading = true;
      this.showModal = !this.showModal;
      this.ngxSmartModalService.open('myModal');
    }
  }

  openModalCategory(type) {
    if (type == 'add') {
      this.categoryForm = {};
      this.ngxSmartModalService.open('myModalCatgeory');
    } else {
      this.ngxSmartModalService.close('myModalCatgeory');
    }
  }

  checkTimeValidation() {
    var selectDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
    var currentDate = moment().format('YYYY-MM-DD');
    if (selectDate == currentDate) {
      this.minTime = moment().add(30, 'minutes').format('h:mm a');
      //this.myForm.start_time = moment().add(1, 'hours').format('h:mm a');
    } else {
      this.minTime = '12:00 am';
    }
    this.checkTargetTime();
    this.checkCurrentDateTime();
  }
  // checkTimeValidationForTarget() {
  //   var selectDate = moment(this.myForm.target_date).format('YYYY-MM-DD');
  //   var currentDate = moment().format('YYYY-MM-DD');
  //   console.log('check----', selectDate + " " + this.myForm.start_time)
  //   this.checkTargetTime();
  // }
  checkTimeValidationForTarget() {
    var selectDate = moment(this.myForm.target_date).format('YYYY-MM-DD');
    var currentDate = moment().format('YYYY-MM-DD');
    var startDate = moment(this.myForm.start_date).format('YYYY-MM-DD');
    console.log('check----', selectDate + " " + this.myForm.start_time)
    if (Number(Date.parse(startDate) / 1000) == Number(Date.parse(selectDate) / 1000)) {
      this.minTargetTime = moment().add(35, 'minutes').format('h:mm a');
      //this.myForm.start_time = moment().add(1, 'hours').format('h:mm a');
    }
    this.checkTargetTime();
  }
  checkTargetTime() {
    var startTime = moment(this.myForm.start_date).format('YYYY-MM-DD') + " " + this.myForm.start_time;
    var targetTime = moment(this.myForm.target_date).format('YYYY-MM-DD') + " " + this.myForm.target_time;
    if (Number(Date.parse(targetTime) / 1000) <= Number(Date.parse(startTime) / 1000)) {
      this.toastr.error('Target date and time should be greater than start date and time.');
      return true;
    } else {
      return false;
    }
  }
  getFriendList() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": "1",
      "page_size": "20"
    }
    this.challengeService.getFriendsListUsingLocation(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.usersList = res.getFriendList;
        for (var i = 0; i < this.usersList.length; i++) {
          this.usersList[i]['isSelected'] = false;
        }
        // console.log('res----list', this.usersList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  addFriend(user) {
    user.isSelected = !user.isSelected;
  }

  closeModal() {
    this.showModal = !this.showModal;
    this.ngxSmartModalService.close('myModal');
    this.onSubmit(true);
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM' || modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    // console.log('print--->>', hours, minutes)
    return `${hours}:${minutes}`;
  }
  checkCurrentDateTime() {
    var startDateTime = moment(this.myForm.start_date).format('YYYY-MM-DD') + " " + this.myForm.start_time
    var currentDateTime = moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    // console.log('currentDateTime===1', currentDateTime);
    // console.log('startDateTime===1', startDateTime);
    startDateTime = moment(new Date(startDateTime.replace(/-/g, "/"))).format('X');
    currentDateTime = moment(new Date(currentDateTime.replace(/-/g, "/"))).format('X');
    console.log('condi====', Number(startDateTime) <= Number(currentDateTime))
    if (Number(startDateTime) <= Number(currentDateTime)) {
      this.toastr.error('The start date and time must be greater than or equal to the current date and time.');
      return false;
    } else {
      return true;
    }
  }
  onSubmit(valid) {
    console.log('valid====>>', valid)
    this.showError = true;
    if (valid && this.checkCurrentDateTime()) {
      this.loading = true;
      this.myForm.type = "1";
      this.myForm.user_id = this.authToken.user_id
      this.myForm["api_key"] = this.authToken.api_key
      this.myForm.start_time = this.convertTime12to24(this.myForm.start_time);
      var splitTime = this.myForm.start_time.split(":")
      var updateDateTime = new Date(this.myForm.start_date).setHours(splitTime[0], splitTime[1]);
      // console.log('check updateDateTime====', updateDateTime);
      this.myForm.date = moment(updateDateTime).format('YYYY-MM-DD H:mm:ss');

      this.myForm.target_time = this.convertTime12to24(this.myForm.target_time);
      var splitTargetTime = this.myForm.target_time.split(":")
      var updateTargetDateTime = new Date(this.myForm.target_date).setHours(splitTargetTime[0], splitTargetTime[1]);
      // this.myForm.target_date = moment(updateTargetDateTime).format('YYYY-MM-DD H:mm:ss');
      this.myForm.challenge_close_limit = moment(updateTargetDateTime).format('YYYY-MM-DD H:mm:ss');
      var checkTime = this.checkTargetTime()
      if (checkTime) {
        console.log('checkTime===2', checkTime)
        this.loading = this.showError = false;
        //return this.toastr.error('Target date and time should be greater than start date and time.');
      } else {
        var data = Object.assign({}, this.myForm);
        data.tag_id = this.tags.toString()
        data.show_to_all = this.myForm.show_to_all == false ? "0" : "1";
        this.challengeService.updateChallengeDetails(data).subscribe(res => {
          if (res.responseCode == 200) {
            this.showModal = false;
            this.loading = this.showError = false;
            this.toastr.success(res.successMessage);
            this.ngxSmartModalService.close('myModal');
            this.router.navigate(['/challenges']);
          } else {
            this.toastr.error(res.successMessage);
            this.loading = false;
          }
        }, (err) => {
          console.log(err);
        });
      }
    }
  }
  createCategory(valid) {
    console.log('valid===', valid)
    this.showCategoryError = true;
    if (valid) {
      this.loading = true;
      this.categoryForm.user_id = this.authToken.user_id
      this.categoryForm["api_key"] = this.authToken.api_key
      this.categoryService.addCategory(this.categoryForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showCategoryError = false;
          this.toastr.success(res.successMessage);
          this.ngxSmartModalService.close('myModalCatgeory');
          this.showModal = false;
          // $("#viewMember").fadeOut();
          // this.showModal = !this.showModal;
          this.categoryListFunction();
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

}
