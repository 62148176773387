// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-all-challenge-loss-list',
//   templateUrl: './all-challenge-loss-list.component.html',
//   styleUrls: ['./all-challenge-loss-list.component.scss']
// })
// export class AllChallengeLossListComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-all-challenge-loss-list',
  templateUrl: './all-challenge-loss-list.component.html',
  styleUrls: ['./all-challenge-loss-list.component.scss']
})
export class AllChallengeLossListComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  callApi: boolean = false;
  challengeLossList: any = [];
  memberList: any = [];
  participationMemberList: any = [];
  member: any = {};
  participationMember: any = {};
  status: any = '1';
  index: any = 0;
  paramsValue: any;
  memberType: any = '1';
  challenge_filter: any = '';
  show_to: any = "1";
  page_no: any = '1';
  page_size: any = '9';
  pagination: any = {};
  apiCalled: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private apiService: ChallengeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "dare_type": this.paramsValue._value.dareType ? this.paramsValue._value.dareType : "mydare",
      "show_to": "1",
      "challenge_filter": this.challenge_filter,
      "page_no": this.page_no,
      "page_size": this.page_size,
      "category_id": this.paramsValue._value.categoryId
    }
    this.apiService.challengeDetails(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengeLossList = res.myChallengeDetail;
        this.pagination = res.pagination;
        this.apiCalled = true;
        // for (var i = 0; i < this.challengeLossList.length; i++) {
        //   if (this.challengeLossList[i] && this.challengeLossList[i].categoryImage) {
        //     this.challengeLossList[i].categoryImage = this.imageUrl + this.challengeLossList[i].categoryImage;
        //   } else {
        //     this.challengeLossList[i].categoryImage = "../../assets/images/weight.png";
        //   }
        // }
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  pageChanged(event) {
    this.page_no = event;
    this.ngOnInit();
  }
  // getDareMemberList(play_id) {
  //   var reqObj = {
  //     "user_id": this.authToken.user_id,
  //     "api_key": this.authToken.api_key,
  //     "play_id": play_id
  //   }
  //   this.dareService.getMemberListUsingPlayId(reqObj).subscribe(res => {
  //     if (res.responseCode == 200) {
  //       this.loading = this.showError = false;
  //       this.memberList = res.userList
  //       if (res && res.userList != 0) {
  //         this.member = this.memberList[0];
  //       }
  //       this.callApi = !this.callApi;
  //     } else if (res.responseCode == 402) {
  //       this.toastr.error('Your session has expired, Please login again.');
  //       this.router.navigate(['/login']);
  //     } else {
  //       this.toastr.error(res.successMessage);
  //       this.loading = false;
  //     }
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

  // getParticipationRequestList(play_id) {
  //   var reqObj = {
  //     "user_id": this.authToken.user_id,
  //     "api_key": this.authToken.api_key,
  //     "play_id": play_id
  //   }
  //   this.dareService.dareParticipationUserDareJoinListList(reqObj).subscribe(res => {
  //     if (res.responseCode == 200) {
  //       this.loading = this.showError = false;
  //       this.participationMemberList = res.userList;
  //       if (res && res.userList != 0) {
  //         this.participationMember = this.participationMemberList[0];
  //       }
  //       this.callApi = !this.callApi;
  //     } else if (res.responseCode == 402) {
  //       this.toastr.error('Your session has expired, Please login again.');
  //       this.router.navigate(['/login']);
  //     } else {
  //       this.toastr.error(res.successMessage);
  //       this.loading = false;
  //     }
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }
  next() {
    var index = this.index + 1;
    if (index <= this.memberList.length - 1) {
      this.index = index;
      this.member = this.memberList[this.index];
    }
  }
  previews() {
    var i = this.index - 1;
    this.index = i == -1 ? 0 : i;
    this.member = this.memberList[this.index]
  }

  nextParticipation() {
    var index = this.index + 1;
    if (index <= this.participationMemberList.length - 1) {
      this.index = index;
      this.participationMember = this.participationMemberList[this.index];
    }
  }
  previewsParticipation() {
    var i = this.index - 1;
    this.index = i == -1 ? 0 : i;
    this.participationMember = this.participationMemberList[this.index]
  }
}
