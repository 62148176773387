import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  backendUrl: any = environment.backendUrl;
  constructor(
    private http: HttpClient
  ) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  };

  getNotificationList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getnotificationlist`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  deleteNotificationList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/deleteNotification`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getNotificationCount(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/notificationsetting`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }
}
