import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';

@Component({
  selector: 'app-all-challenge-won-lost-request-list',
  templateUrl: './all-challenge-won-lost-request-list.component.html',
  styleUrls: ['./all-challenge-won-lost-request-list.component.scss']
})
export class AllChallengeWonLostRequestListComponent implements OnInit {

  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  challengeWonList: any = [];
  challengeLossList: any = [];
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  showWon: boolean = true;
  showLoss: boolean = false;
  show_to_won: any = '1';
  show_to_loss: any = '1';
  apiCalled: boolean = false;
  won_page_no: any = '1';
  loss_page_no: any = '1';
  won_page_size: any = '9';
  loss_page_size: any = '9';
  wonPagination: any = {};
  lossPagination: any = {};
  constructor(
    private challengeService: ChallengeService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    this.getChallengeWonList();
  }

  getChallengeWonList() {
    this.showWon = true;
    this.showLoss = false;
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      // "dare_type": "mydare",
      "show_to": this.show_to_won || "1",
      "page_no": this.won_page_no,
      "page_size": this.won_page_size
    }
    this.challengeService.challengeWonList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengeWonList = res.myChallengeList;
        this.wonPagination = res.pagination;
        this.apiCalled = true;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getChallengeLossList() {
    this.showWon = false;
    this.showLoss = true;
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "show_to": "2",
      "page_no": this.loss_page_no,
      "page_size": this.loss_page_size
    }
    this.challengeService.challengesLossList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengeLossList = res.myChallengeList;
        this.lossPagination = res.pagination;
        this.apiCalled = true;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  wonPageChanged(event) {
    this.won_page_no = event;
    this.ngOnInit();
  }
  lossPageChanged(event) {
    this.loss_page_no = event;
    this.getChallengeLossList();
  }
}
