import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { LocalStorageService } from '../../helper/localStorage.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { googlemaps } from 'googlemaps';
declare module 'googlemaps';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('gmap', { static: false }) gmapElement: any;
  map: google.maps.Map;

  isTracking = false;
  address: string = '';
  isShowPassword = false;
  currentLat: any;
  currentLong: any;
  myForm: any = {};
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  selectUndefinedValue: any;
  emailPattern: any = /^[a-z0-9]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;//[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$
  qualificationList: any = [];
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 3
  };
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private socialAuthService: AuthService
  ) { }

  ngOnInit() {
    // this.authService.clearPreviousLoginSession();
  }

  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  toggleFieldTextType() {
    this.isShowPassword = !this.isShowPassword;
  }
  onSubmit(valid) {
    this.submitted = true;
    this.showError = true;
    this.myForm['user_id'] = this.authToken.user_id;
    this.myForm['api_key'] = this.authToken.api_key
    if (valid) {
      this.loading = true;
      if (this.myForm.password === this.myForm.confirmPassword) {
        console.log('check req====', this.myForm)
        this.userService.resetPassword(this.myForm).subscribe(res => {
          if (res.responseCode == 200) {
            this.loading = false;
            this.submitted = false;
            this.showError = false;
            this.myForm = {}
            this.toastr.success(res.responseMessage);
            this.router.navigate(['/login']);
          } else {
            this.toastr.error(res.responseMessage);
            this.loading = false;
            this.submitted = false;
            this.showError = false;
          }
        }, (err) => {
          console.log(err);
        })
      } else {
        // this.msgError = 'Confirm password should be same as new password'
        this.toastr.error('Confirm password should be same as new password');
        this.loading = false;
      }
    }
  }
}
