import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUSComponent implements OnInit {
  aboutus: any;
  loading: boolean = false;
  isCheck: boolean = false;
  showError: boolean = false;
  submitted: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "id": "17",
      "api_key": this.authToken.api_key
    }
    console.log('call aboutus', reqObj)
    this.userService.getAboutUS(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.aboutus = (res && res.staticpage) ? res.staticpage[0].page_dscription : '';
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
