import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { ChatService } from '../../../apiServices/chat/chat.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
import { Event } from '../../../_models/event';
// import { Event } from '../../apiServices/model/event';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  myForm: any = {};
  loading: boolean = false;
  showError: boolean = false;
  count: any;
  isShowHide: boolean = true;
  isLogin: boolean = false;
  isSignup: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private socketService: ChatService
  ) { }

  ngOnInit() {
    setInterval(() => {
      if (localStorage.getItem('whichScreen') == 'forgot') {
        var data = JSON.parse(localStorage.getItem('forgotResponse'))
        // this.myForm.otp = data.password;
        this.myForm.mobile = data.mobile;
      } else {
        // this.myForm.otp = this.authToken.otp;
      }
    }, 5000);
    this.startCountdown(20);
    this.isLogin = localStorage.getItem('whichScreen') == 'login' ? true : false;
    this.isSignup = localStorage.getItem('whichScreen') == 'signup' ? true : false;
  }
  startCountdown(seconds) {
    var counter = seconds;
    var interval = setInterval(() => {
      // console.log(counter);
      this.count = '00 : ' + counter;
      counter--;
      if (counter < 0) {
        this.isShowHide = false;
        clearInterval(interval);
        console.log('Ding!');
      };
    }, 1000);
  };



  onSubmit(valid) {
    this.showError = true;
    var reqObj = {}
    if (valid) {
      this.loading = true;
      if (localStorage.getItem('whichScreen') == 'forgot') {
        reqObj = {
          "mobile": this.myForm.mobile,
          "otp": this.myForm.otp
        }
      } else {
        reqObj = {
          "mobile": this.authToken.mobile,
          "otp": this.myForm.otp
        }
      }
      console.log('check request===', reqObj)
      this.userService.otpVerify(reqObj).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.storage.saveLoginUserDetails(res.userDetails);
          if (localStorage.getItem('whichScreen') == 'forgot') {
            this.router.navigate(['/reset_password']);
          } else {
            this.initIoConnection();
            this.router.navigate(['/dashboard']);
          }
        } else if (res.responseCode == 400) {
          this.toastr.error(res.successMessage);
          this.loading = false;
        } else {
          this.toastr.error(res.responseMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  sendAgain(valid) {
    var reqObj = {
      "mobile": this.authToken.mobile
    }
    this.userService.resendOtp(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.toastr.success(res.successMessage);
        this.authToken['otp'] = res.otp;
        this.isShowHide = true;
        this.startCountdown(20);
        this.storage.saveLoginUserDetails(this.authToken);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  private initIoConnection(): void {
    this.socketService.initSocket();

    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        console.log('connected');
      });
    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        console.log('disconnected');
      });
  }


}
