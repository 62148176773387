import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';

@Component({
  selector: 'app-dashboard-best-of-the-week',
  templateUrl: './dashboard-best-of-the-week.component.html',
  styleUrls: ['./dashboard-best-of-the-week.component.scss']
})
export class DashboardBestOfTheWeekComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  dareOftheWeekList: any = [];
  dareDetails: any = {};
  status: any = '1';
  memberType: any = '1';
  isEdit: any;
  paramsValue: any;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  date: any = new Date();
  firstDay: any = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  lastDay: any = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  selected: any = { start: '', end: '' };
  options: any = {
    confirmBtnClass: 'btn btn-success', //DEFAULT VALUE
    confirmBtnText: 'Confirm', //DEFAULT VALUE
    cancelBtnClass: 'btn btn-danger', //DEFAULT VALUE
    cancelBtnText: 'Cancel', //DEFAULT VALUE
    modalSize: 'sm', //DEFAULT VALUE
    modalClass: '' //DEFAULT VALUE
  };

  constructor(
    private apiService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    // this.checkisEditStatus();
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": "1",
      "page_size": "5",
      "dare_from": (this.selected && this.selected.start) ? new Date(this.selected.start) : '',
      "dare_to": (this.selected && this.selected.end) ? new Date(this.selected.end) : ''

    }
    this.apiService.dareOftheWeekList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.dareOftheWeekList = res.myWeeklyList;
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  showDetails(data) {
    // console.log('check---', data)
    this.dareDetails = data;
  }

}
