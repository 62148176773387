import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';
import * as moment from 'moment';
import { DatepickerOptions } from 'ng2-datepicker';


@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  private base64textString: String = "";
  myForm: any = {
    gender: ""
  };
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/uploads/';
  loading: boolean = false;
  showError: boolean = false;
  submitted: boolean = false;
  profile: any = {};
  status: any = '1';
  profileImage: any = '../../assets/images/user_avatar_thumbnail.png';
  memberType: any = '1';
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  minDate: any = moment().subtract('years', 18);
  maxYear: any = moment().subtract('years', 15).year();
  maxDate: any = moment().subtract('years', 15);
  date: any = new Date(this.minDate);
  qualificationList: any = [];
  dateOptions: DatepickerOptions = {
    maxYear: this.maxYear,
    // maxYear: 2030,
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    // minDate: new Date(this.minDate), // Minimal selectable date
    maxDate: new Date(this.maxDate), // Maximal selectable date
    barTitleIfEmpty: 'Select DOB',
    placeholder: 'Select DOB', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 999999,
    placeholder: 'Select Hobby'
  };
  isEmailVerified: boolean = true;
  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    this.getQualification();
    this.checkEmailVerifyStatus();
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.userService.getProfile(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.profile = res.userDetails[0];
        this.profile.dob = res.userDetails[0].dob ? new Date(res.userDetails[0].dob) : this.authToken.dob ? new Date(this.authToken.dob) : '';
        this.profile.about_me = res.userDetails[0].about_me ? res.userDetails[0].about_me : this.authToken.about_me;
        this.profile.image = (this.profile.image || this.authToken.image) ? (res.userDetails[0].image || this.authToken.image) : this.profileImage
        this.profile['isDisabled'] = this.profile.mobile ? true : false
        this.profile['isEmailDisabled'] = this.profile.email ? true : false
        // var qualificationData = this.profile.qualification.split(",")
        console.log('profile====', this.profile)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });

  }
  checkEmailVerifyStatus() {
    var reqObj = { "user_id": this.authToken.user_id, "email": this.authToken.email, "api_key": this.authToken.api_key }
    this.userService.getEmailVerify(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.isEmailVerified = res.status == 0 ? false : true
        // this.qualificationList = res.qualificationList;
        // console.log(this.qualificationList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  verifyEmail() {
    var reqObj = { "user_id": this.authToken.user_id, "email": this.authToken.email, "api_key": this.authToken.api_key }
    this.userService.emaillinkVerify(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        // this.isEmailVerified = res.status == 0 ? false : true
        // this.qualificationList = res.qualificationList;
        // console.log(this.qualificationList)
        this.toastr.success(res.successMessage)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  handleFileSelect(evt) {
    // var files = evt.target.files;
    // var file = files[0];
    // if (files.length === 0)
    //   return;
    // var mimeType = files[0].type;
    // if (files && file) {
    //   var reader = new FileReader();
    //   reader.onload = this._handleReaderLoaded.bind(this);
    //   reader.readAsBinaryString(file);
    //   var reader = new FileReader();
    //   reader.readAsDataURL(files[0]);
    //   reader.onload = (_event) => {
    //     this.profile.image = reader.result;
    //   }
    // }
    var files = evt.target.files;
    if (files.length === 0)
      return;
    const fsize = files[0].size;
    const file = Math.round((fsize / 1024 / 1024));
    var mimeType = files[0].type;
    console.log('call size====', fsize, file, mimeType)
    if (!(file <= 100)) {
      return this.toastr.warning("File too Big, please select a file less than 100mb")
    }
    // if (mimeType.match(/image\/*/) == null) {
    //   this.message = "Only images are supported.";
    //   return;
    // }
    var reader = new FileReader();
    // this.imagePath = files;
    this.myForm.file = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.profile.image = reader.result;
    }

  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    // console.log(btoa(binaryString));
  }


  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  onSubmit(valid) {
    console.log('====valid===', valid)
    this.showError = true;
    this.submitted = true;
    if (valid) {
      this.loading = true;
      this.profile['profile_pic'] = this.myForm.file;
      this.profile.email = this.profile.email;
      // this.profile.qualification = this.profile.qualification;
      this.profile['api_key'] = this.authToken.api_key;
      this.profile.dob = moment(this.profile.dob).format('YYYY-MM-DD');
      delete this.profile.userImage
      // if (this.isObject(this.profile.qualification)) {
      //   this.profile.qualification = this.profile.qualification.name
      // } else {
      //   this.profile.qualification = this.profile.qualification
      // }
      if (Array.isArray(this.profile.qualification)) {
        var getNames = this.profile.qualification.filter(val => val.name).map((val) => val.name);
        this.profile.qualification = getNames.toString()
      }
      this.myForm.about_me = (this.myForm.about_me == "undefined" || this.myForm.about_me == undefined) ? '' : this.myForm.about_me;
      console.log('profile---', this.profile.gender);
      this.userService.updateProfile(this.profile).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.authToken['about_me'] = res.userDetails.about_me;
          this.authToken['gender'] = res.userDetails.gender;
          this.authToken['dob'] = res.userDetails.dob;
          this.authToken['qualification'] = res.userDetails.qualification;
          this.authToken['mobile'] = res.userDetails.mobile;
          this.authToken['email'] = res.userDetails.email;
          this.authToken['user_name'] = res.userDetails.user_name;
          this.authToken['name'] = res.userDetails.name;
          this.authToken['image'] = res.userDetails.image;
          this.authToken['longitude'] = res.userDetails.longitude;
          this.authToken['latitude'] = res.userDetails.latitude;
          this.authToken['location'] = res.userDetails.location;
          this.storage.saveLoginUserDetails(this.authToken);
          this.router.navigate(['/profile']);
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }
  getQualification() {
    this.userService.getQualifications().subscribe(res => {
      if (res.responseCode == 200) {
        this.qualificationList = res.qualificationList;
        // console.log(this.qualificationList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
