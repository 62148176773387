import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/uploads/';
  loading: boolean = false;
  showError: boolean = false;
  profile: any = [];
  status: any = '1';
  profileImage: any = '../../assets/images/user_avatar_thumbnail.png';
  memberType: any = '1';
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));

  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.userService.getProfile(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.profile = res.userDetails;
        this.profile.userImage = (this.profile.userImage || this.authToken.image) ? (res.userDetails.userImage || this.authToken.image) : this.profileImage
        this.profile.notificationSetting = this.profile.notificationSetting ? this.profile.notificationSetting : this.authToken.notificationSetting
        this.profile['isChecked'] = this.profile.notificationSetting == "0" ? false : true;
        console.log('profile====', this.profile)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  showMessage() {
    this.toastr.success('Coming soon.');
  }
  notificationSettingChanged() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "status": this.profile.isChecked == false ? "0" : "1",
      "api_key": this.authToken.api_key
    }
    this.userService.changeNotificationSetting(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.toastr.success(res.successMessage);
        this.authToken['notificationSetting'] = this.profile.isChecked == false ? "0" : "1";
        this.storage.saveLoginUserDetails(this.authToken);
      } else {
        this.toastr.error(res.successMessage);
      }
    }, (err) => {
      console.log(err);
    });
  }
}
