import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../apiServices/dare/dare.service';
import { UserService } from '../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import { ChallengeService } from '../../apiServices/challenge/challenge.service';

@Component({
  selector: 'app-dashboard-earning-bets-won',
  templateUrl: './dashboard-earning-bets-won.component.html',
  styleUrls: ['./dashboard-earning-bets-won.component.scss']
})
export class DashboardEarningBetsWonComponent implements OnInit {

  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  dareWonList: any = [];
  dareLossList: any = [];
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  showWon: boolean = true;
  showLoss: boolean = true;
  show_to_won: any = '1';
  show_to_earn: any = '1';
  show_to_loss: any = '1';
  totalEarning: any;
  earningList: any = [];
  apiCalled: boolean = false;
  showDareEarn: boolean = true;
  constructor(
    private apiService: UserService,
    private dareService: DareService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private challengeService: ChallengeService,
  ) { }

  ngOnInit() {
    this.getDareWonList();
    this.getEarningList();
  }

  getDareWonList() {
    this.showWon = true;
    this.showLoss = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "dare_type": "mydare",
      "show_to": this.show_to_won || "1",
      "page_no": "1",
      "page_size": "3"
    }
    this.dareService.daresWonList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.dareWonList = res.myDareList;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getDareLossList() {
    this.showWon = false;
    this.showLoss = true;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "show_to": this.show_to_loss || "1",
      "page_no": "1",
      "page_size": "3"
    }
    this.dareService.daresLossList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.dareLossList = res.myDareList;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getEarningList() {
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": "1",
      "show_to": this.show_to_earn,
      "page_size": "5"

    }
    this.dareService.dareEarningList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.earningList = res.myEarningList ? res.myEarningList : [];
        this.apiCalled = true;
      } else if (res.responseCode == 402) {
        // this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getChallengeEarningList() {
    this.apiCalled = false;
    this.showDareEarn = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": "1",
      "show_to": "2",
      "page_size": "5"

    }
    this.challengeService.getChallengeEarnList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.earningList = res.myEarningList ? res.myEarningList : [];
        this.apiCalled = true;
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
