import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
// import * as $ from 'jquery';
import * as moment from 'moment';
import Swal from 'sweetalert2'

declare var $: any;


@Component({
  selector: 'app-new-all-dares',
  templateUrl: './new-all-dares.component.html',
  styleUrls: ['./new-all-dares.component.scss']
})
export class NewAllDaresComponent implements OnInit {
  // @HostBinding('class') defaultClass = 'owl-carousel';
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;

  myForm: any = {};
  message: any = {};
  searching: any = "";
  carouselData: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  apiCalledForMemberList: boolean = false;
  showError: boolean = false;
  apiCalled: boolean = false;
  seeMore: boolean = true;
  getAllDaresList: any = [];
  pagination: any = {};
  memberList: any = [];
  participationMemberList: any = [];
  member: any = {};
  participationMember: any = {};
  status: any = '1';
  index: any = 0;
  paramsValue: any;
  memberType: any = '1';
  page_no: any = '1';
  page_size: any = '9';
  dare_filter: any = '1';
  config: any;
  date_now: any;
  date_future: any;
  isShowThumnail: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'Are you sure, you want to delete this dare?';
  popoverInactivateMessage: string = 'Are you sure, you want to deactivate this user?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  mySlideOptions: any = { items: 4, dots: true, nav: false };
  imageLoader: boolean = true;
  isSearchCall: boolean = false;
  showMsgCount: any = JSON.parse(localStorage.getItem('countAlert'));
  shareUrl: any = '';
  constructor(
    private dareService: DareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.showMsgCount = JSON.parse(localStorage.getItem('countAlert'));
    if (this.showMsgCount == null) {
      localStorage.setItem('countAlert', JSON.stringify({ user_id: this.authToken.user_id, count: 0 }))
    }
    console.log('this.showMsgCount==', this.showMsgCount);
    if (this.showMsgCount != null && this.showMsgCount.count == 0 && Number(this.showMsgCount.user_id) == Number(this.authToken.user_id)) {
      this.showAlertMessage();
    }
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "page_no": this.page_no,
      "page_size": this.page_size,
      "search": this.searching ? this.searching : ''
    }
    // console.log('check--- req--', reqObj);
    this.dareService.getAllDares(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        if (this.page_no == "1") {
          this.getAllDaresList = [];
          this.isSearchCall = false;
          console.log(this.getAllDaresList, this.isSearchCall)
        }
        this.getAllDaresList = [...this.getAllDaresList, ...res.details]
        for (let i = 0; i < this.getAllDaresList.length; i++) {
          //  const element = this.getAllDaresList[i];
          // for (let k = 0; k < this.getAllDaresList[i].length; k++) {
          //   this.getAllDaresList[i][k]['isActive'] = false;
          // }
          if (this.getAllDaresList[i].play_type == '1') {
            this.getAllDaresList[i]['navigationUrl'] = '/view_dare_details/' + this.getAllDaresList[i].play_id
          } else {
            this.getAllDaresList[i]['navigationUrl'] = '/view_challenge_details/' + this.getAllDaresList[i].play_id
          }
          this.checkVideoAndImage(this.getAllDaresList[i].proof_list, this.getAllDaresList[i], 'getAll')
        }
        console.log('seemore check', res.pagination.maxPages, this.page_no)
        if (res.pagination.maxPages == Number(this.page_no)) {
          this.seeMore = false;
        }
        this.pagination = res.pagination;
        this.apiCalled = true;

      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  toggleVideo(event: any) {
    this.isShowThumnail = true;
    // this.carouselData.video_url = 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'
    // this.videoplayer.nativeElement.play();
  }
  onScroll() {
    this.loading = true;
    this.page_no = (Number(this.page_no) + 1).toString();
    console.log("Scrolled page_no===", this.page_no);
    // this.page_size = Number(this.page_size) + 3;
    // console.log("Scrolled", this.page_size);
    this.ngOnInit();
  }
  searchFunction() {
    this.loading = true;
    console.log("Scrolled page_no===", this.searching.length);
    this.page_no = "1";
    if (this.searching.length > 2) {
      // this.getAllDaresList = [];
      this.isSearchCall = true;
      this.ngOnInit();
    }
    if (this.searching.length == 0) {
      this.isSearchCall = true;
      this.ngOnInit();
    }
  }
  pageChanged(event) {
    this.page_no = event;
    this.ngOnInit();
  }
  gettimeDiff(createAt) {
    this.date_future = new Date();
    this.date_now = new Date(createAt);
    var seconds = Math.floor((this.date_future - (this.date_now)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    return days > 0 ? `${days} days` : hours > 0 ? `${hours} hours` : minutes > 0 ? `${minutes} minutes` : seconds > 0 ? `${seconds} seconds` : '';
  }

  delete(dare) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": dare.play_id
    }
    this.dareService.deleteDare(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.toastr.success(res.successMessage);
        this.ngOnInit();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  checkVideoAndImage(data, dare, type) {
    // console.log('check details====', data, dare, type)
    if (type == 'getAll') {
      for (let i = 0; i < dare.proof_list.length; i++) {
        dare.proof_list[i]['isActive'] = false;
      }
      data[0]['isActive'] = true;
      dare['showProofDetails'] = data[0]
    } else {
      for (let i = 0; i < dare.proof_list.length; i++) {
        dare.proof_list[i]['isActive'] = false;
      }
      data['isActive'] = true;
      dare['showProofDetails'] = data
      this.isShowThumnail = false;
    }
    // console.log('checkVideoAndImage====', data)
    // this.carouselData = data;
  }
  getAge(DOB: any) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }
  getDareMemberList(play_id, proof_list) {
    this.memberList = proof_list
    console.log('check member details====', this.memberList)
    this.apiCalledForMemberList = true;
    this.member = this.memberList[0];
    // this.memberList = [];
    // var reqObj = {
    //   "user_id": this.authToken.user_id,
    //   "api_key": this.authToken.api_key,
    //   "play_id": play_id
    // }
    // this.dareService.getMemberListUsingPlayId(reqObj).subscribe(res => {
    //   if (res.responseCode == 200) {
    //     this.loading = this.showError = false;
    //     this.memberList = res.userList
    //     if (res && res.userList != 0) {
    //       this.member = this.memberList[0];
    //     }
    //     this.apiCalledForMemberList = true;
    //   } else if (res.responseCode == 402) {
    //     this.toastr.error('Your session has expired, Please login again.');
    //     this.router.navigate(['/login']);
    //   } else {
    //     this.toastr.error(res.successMessage);
    //     this.loading = false;
    //   }
    // }, (err) => {
    //   console.log(err);
    // });
  }
  next() {
    var index = this.index + 1;
    if (index <= this.memberList.length - 1) {
      this.index = index;
      this.member = this.memberList[this.index];
    }
  }
  previews() {
    var i = this.index - 1;
    this.index = i == -1 ? 0 : i;
    this.member = this.memberList[this.index]
  }

  allDareDetails(dareDetails) {
    localStorage.setItem('allDare', JSON.stringify(dareDetails))
  }

  // ngAfterViewInit(): void {
  //   $('#owl-carousel').owlCarousel({
  //     loop: false,
  //     margin: 20,
  //     nav: true,
  //     dots: false,
  //     responsive: {
  //       0: { items: 2 },
  //       600: { items: 4 },
  //       1000: { items: 9 }
  //     }
  //   });
  // }
  shareOption(data) {
    console.log('data----', data)
    this.shareUrl = window.location.origin + data.navigationUrl
  }
  showAlertMessage() {
    // this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.dareService.getAlertMessage(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.message = res.alertMessageList;
        if (res.alertMessageList.publish_type == "1") {
          Swal.fire('Alert!', this.message.message, 'success')
        }
        // this.apiCalled = true;
        localStorage.setItem('countAlert', JSON.stringify({ user_id: this.authToken.user_id, count: 1 }))
        console.log('res----', this.message)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
