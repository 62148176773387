import { Injectable } from '@angular/core';

@Injectable()
export class ChatUtil {

    name: string;
    userName: string;
    message: string;

    constructor() { }

    getLastMessageOwnerName(data: any, userId: string) {
        this.userName = '';
        if (data && data.lastMessage && data.lastMessage.senderId) {
            return '';
            // return data.lastMessage.sender_detail.name;
            // for(let obj of data.participants){
            //   if(obj._id == data.lastMessage.senderId){
            //     this.userName = obj.name;
            //     break;
            //   }
            // }    
        }
        else {
            for (let obj of data.participants) {
                if (obj._id == userId) {
                    this.userName = obj.name;
                    break;
                }
            }
        }
        return this.userName;
    }



    getLastMessage(data: any, userId: string) {
        this.message = "";
        if (data && data.lastMessage && data.lastMessage.senderId) {
            return data.lastMessage.message;
        } else {
            for (let obj of data.participants) {
                if (obj._id == data.groupOwner) {
                    this.name = obj.name;
                    break;
                }
            }
            this.message = `created group "${data.groupName}"`;
            if (this.name) {
                this.message = data.groupOwner == userId ? `You ${this.message}` : `${this.name} ${this.message}`;
            }
        }
        return this.message;
    }

    getUserName(userId: string, activeRoom: any) {
        var name = "";
        for (var user of activeRoom.participants) {
            if (user._id == userId) {
                name = user.name;
                break;
            }
        }
        return name;
    }

    getTick(msg: any) {
        if (!msg.receiversId || msg.receiversId.length == 1) {
            return 'singleTick';
        }
        if (msg.isRead && msg.receiversId.length - 1 == msg.isRead.length) {
            return "blueTick";
        }
        else if (msg.isDeliveredArray && msg.receiversId.length - 1 == msg.isDeliveredArray.length) {
            return "doubleTick";
        }
        return "singleTick";
    }

    catchFile(files) {
        if (files && files.length) {
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var filetype = file.type.substring(0, file.type.indexOf('/'));
                if (filetype == "image") return "image";
                else return "invalid format";

                // if (file.type == "application/pdf" || file.type == "application/msword" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type == "text/plain" || file.type == "application/vnd.ms-excel") {
                //   return "document";
                // }else if(file.type == "audio/mp3" || file.type == "audio/mpeg"){
                //   return "music";
                // }else{
                //   var filetype = file.type.substring(0,file.type.indexOf('/'));
                //   if (filetype == "image") {
                //     return "image";
                //   }else{
                //     return "invalid format";
                //   }
                // }
            }
        }
    }

    isGroupMember(participants, userId) {
        var flag = false;
        for (var i = 0; i < participants.length; i++) {
            if (userId == participants[i]) {
                flag = true;
                break;
            }
        }
        return flag;
    }
}
