import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss']
})
export class BankAccountComponent implements OnInit {
  myForm: any = {};
  loading: boolean = false;
  isCheck: boolean = false;
  showError: boolean = false;
  submitted: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'Are you sure, you want to delete this bank detail?';
  popoverInactivateMessage: string = 'Are you sure, you want to deactivate this user?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key
    }
    this.userService.getBankAccountDetail(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.isCheck = this.isObjectEmpty(res.bankList)
        if (this.isCheck && res.bankList.length != 0) {
          this.myForm = res.bankList[0] || {};
          this.myForm['account_address'] = res.bankList[0].acc_address;
        } else {
          this.myForm = {};
        }
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  isObjectEmpty(Obj) {
    if (Obj.length == 0) return false;
    for (var key in Obj) {
      // if (Obj.hasOwnProperty(key))
      //   return false;
      if (Object.keys(Obj).length === 0) {
        console.log("No properties")
        return false;
      }
    }
    return true;
  }
  isEdit() {
    if (this.myForm && this.myForm.account_name) {
      this.isCheck = !this.isCheck;
    } else {
      this.router.navigate(['/profile']);
    }
  }

  onSubmit(valid) {
    this.showError = true;
    if (valid) {
      this.loading = true;
      this.myForm.user_id = this.authToken.user_id;
      this.myForm['api_key'] = this.authToken.api_key;
      if (this.myForm.bank_id) {
        this.userService.editBankAccountDetail(this.myForm).subscribe(res => {
          if (res.responseCode == 200) {
            this.loading = this.showError = false;
            this.toastr.success(res.successMessage);
            this.router.navigate(['/profile']);
          } else if (res.responseCode == 402) {
            this.toastr.error(res.successMessage);
            this.router.navigate(['/login']);
          } else if (res.responseCode == 400) {
            this.toastr.error(res.successMessage);
          } else {
            this.toastr.error(res.successMessage);
            this.loading = false;
          }
        }, (err) => {
          console.log(err);
        });
      } else {
        this.userService.addBankAccountDetail(this.myForm).subscribe(res => {
          if (res.responseCode == 200) {
            this.loading = this.showError = false;
            this.toastr.success(res.successMessage);
            this.router.navigate(['/profile']);
          } else {
            this.toastr.error(res.successMessage);
            this.loading = false;
          }
        }, (err) => {
          console.log(err);
        });
      }
    }
  }
  deleteBankDetails(id) {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "bank_id": id
    }
    this.userService.deleteBankAccountDetail(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.toastr.success(res.successMessage);
        this.ngOnInit();
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
