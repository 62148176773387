import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dares-won',
  templateUrl: './dares-won.component.html',
  styleUrls: ['./dares-won.component.scss']
})
export class DaresWonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
