import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';

@Component({
  selector: 'app-mydares',
  templateUrl: './mydares.component.html',
  styleUrls: ['./mydares.component.scss']
})
export class MydaresComponent implements OnInit {
  myForm: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  activeBetList: any = [];
  show_to: any = "";
  pagination: any = {};
  page_no: any = '1';
  page_size: any = '9';
  dare_filter: any = '1';
  dareFilter: any;
  dare_type: any = "mydare";
  apiCalled: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  constructor(
    private dareService: DareService,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
    // this.show_to = localStorage.getItem('dare_show_to')
    this.apiCalled = false;
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "dare_type": this.dare_type,
      "show_to": this.show_to,
      "page_no": this.page_no,
      "page_size": this.page_size
    }
    this.dareService.getActiveBets(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        localStorage.setItem('dare_show_to', this.show_to);
        this.loading = this.showError = false;
        this.activeBetList = res.myDareList;
        this.pagination = res.pagination;
        this.apiCalled = true;
        // console.log('res----', this.activeBetList)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.responseMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  pageChanged(event) {
    this.page_no = event;
    this.ngOnInit();
  }

}
