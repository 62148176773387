import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../helper/localStorage.service';
import { AuthServiceService } from '../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatepickerOptions } from 'ng2-datepicker';
import * as moment from 'moment';
import { UserService } from '../../apiServices/user/user.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-write-to-us',
  templateUrl: './write-to-us.component.html',
  styleUrls: ['./write-to-us.component.scss']
})
export class WriteToUsComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  myForm: any = {};
  categoryList: any;
  showModal: boolean = false;
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));

  constructor(
    private apiService: UserService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {
  }


  onSubmit(valid) {
    console.log('valid===', valid)
    this.showError = true;
    this.showModal = !this.showModal;
    if (valid) {
      this.loading = true;
      this.myForm.user_id = this.authToken.user_id
      this.myForm["api_key"] = this.authToken.api_key
      this.apiService.writeToUs(this.myForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.toastr.success(res.successMessage);
          this.showModal = false;
          this.myForm = {};
          // $("#viewMember").fadeOut();
          // this.showModal = !this.showModal;
          this.ngOnInit();
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

}
