import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-bets',
  templateUrl: './active-bets.component.html',
  styleUrls: ['./active-bets.component.scss']
})
export class ActiveBetsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
