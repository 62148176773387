import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../apiServices/category/category.service';
import { DareService } from '../../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';

@Component({
  selector: 'app-view-dare',
  templateUrl: './view-dare.component.html',
  styleUrls: ['./view-dare.component.scss']
})
export class ViewDareComponent implements OnInit {
  myForm: any = {};
  memberDetails: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  myBetDetails: any = [];
  status: any = '1';
  memberType: any = '1';
  isEdit: any;
  paramsValue: any;
  tags: any = [];
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  shareUrl: any = '';
  constructor(
    private categoryService: CategoryService,
    private dareService: DareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.checkisEditStatus();
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    // this.dareService.getDareDetailsUsingPlayId(reqObj).subscribe(res => {
    this.dareService.getDareDetails(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.myBetDetails = res.details;
        this.tags = (res.details.tag_list).split(",")
        console.log('check value---', this.myBetDetails)
        // this.myBetDetails = JSON.parse(localStorage.getItem('dare'));
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  checkisEditStatus() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    this.dareService.getDareDetailsUsingPlayId(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.isEdit = res.status;
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  showDetails(data) {
    // console.log('check---', data)
    this.memberDetails = data;
  }
  shareOption(data) {
    console.log('data----', data)
    this.shareUrl = window.location.origin + '/view_dare_details/' + data.play_id;
    console.log('this.shareUrl====', this.shareUrl)
  }
}
