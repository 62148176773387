import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private router: Router) { }


  //Set User Secure Token
  setSecureProfile(secure_profile: string) {
    localStorage.setItem("LoggedIn", secure_profile)
  }

  //Set User Secure Token
  getSecureToken() {
    return localStorage.getItem("LoggedIn")
  }

  //Check User is LoggedIn or not!
  isLoggednIn() {
    return this.getSecureToken() !== null;
  }

  //Logout method
  logout() {
    localStorage.removeItem("LoggedIn");
    localStorage.removeItem("l_u_d");
    localStorage.removeItem("countAlert");
    this.router.navigate(["login"]);
  }

  //clear prev login session method
  clearPreviousLoginSession() {
    localStorage.removeItem("LoggedIn");
    localStorage.removeItem("l_u_d");
  }
}
