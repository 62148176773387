import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../apiServices/user/user.service';
import { LocalStorageService } from '../../helper/localStorage.service';
import { AuthServiceService } from '../../../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { googlemaps } from 'googlemaps';
declare module 'googlemaps';
import * as moment from 'moment';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @ViewChild('gmap', { static: false }) gmapElement: any;
  map: google.maps.Map;

  isTracking = false;
  address: string = '';
  isShowPassword = false;
  currentLat: any;
  currentLong: any;
  myForm: any = {};
  loading: boolean = false;
  submitted: boolean = false;
  showError: boolean = false;
  selectUndefinedValue: any;
  emailPattern: any = /^[a-z0-9]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;//[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$
  qualificationList: any = [];
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 999999,
    placeholder: 'Select Hobby'
  };
  minDate: any = moment().subtract('years', 18);
  maxYear: any = moment().subtract('years', 15).year();
  maxDate: any = moment().subtract('years', 15);
  date: any = new Date(this.minDate);
  dateOptions: DatepickerOptions = {
    maxYear: this.maxYear,
    // maxYear: 2030,
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    // minDate: new Date(this.minDate), // Minimal selectable date
    maxDate: new Date(this.maxDate), // Maximal selectable date
    barTitleIfEmpty: 'Select DOB',
    placeholder: 'Select DOB', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  isValidUsername: boolean = false;
  isNotValidUsername: boolean = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    private socialAuthService: AuthService
  ) { }

  ngOnInit() {
    this.authService.clearPreviousLoginSession();
    this.getQualification();
    this.findMe();
  }
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  showPosition(position) {
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;
    console.log('current location======', position)
    this.getAddress(this.currentLat, this.currentLong)
  }
  getQualification() {
    this.userService.getQualifications().subscribe(res => {
      if (res.responseCode == 200) {
        this.qualificationList = res.qualificationList;
        // console.log(this.qualificationList)
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getAddress(latitude, longitude) {
    var geocoder = geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          // this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  toggleFieldTextType() {
    this.isShowPassword = !this.isShowPassword;
  }
  verifyUsername() {
    console.log('check value----', this.myForm)
    if (this.myForm && this.myForm.name && (this.myForm.name.length > 3)) {
      var reqObj = { "name": this.myForm.name }
      this.userService.checkUserName(reqObj).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.isValidUsername = true;
          this.isNotValidUsername = false;
        } else {
          this.isNotValidUsername = true;
          this.isValidUsername = false;
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.isValidUsername = false;
      this.isNotValidUsername = false;
    }
  }
  onSubmit(valid) {
    // console.log('check submit---', valid, this.myForm)
    this.showError = true;
    if (valid) {
      this.loading = true;
      this.myForm.latitude = this.currentLat;
      this.myForm.longitude = this.currentLong;
      this.myForm.location = this.address;
      this.myForm.deviceType = this.myForm.deviceToken = 'Web'
      if (Array.isArray(this.myForm.qualification)) {
        var getNames = this.myForm.qualification.filter(val => val.name).map((val) => val.name);
        this.myForm.qualification = getNames.toString()
      }
      console.log('check form----', this.myForm)
      this.userService.registration(this.myForm).subscribe(res => {
        if (res.responseCode == 200) {
          this.loading = this.showError = false;
          this.storage.saveLoginUserDetails(res.userDetails);
          this.toastr.success(res.successMessage);
          this.router.navigate(['/verifyOtp']);
          localStorage.setItem('whichScreen', 'signup');
        } else {
          this.toastr.error(res.successMessage);
          this.loading = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        console.log(socialPlatform + " sign in data : ", userData);
        this.myForm = userData;
        this.myForm.social_type = socialPlatform || userData.provider;
        this.myForm.social_id = userData.id;
        this.myForm.user_name = userData.name;
        this.myForm.profile_pic = userData.image;
        this.myForm.latitude = this.currentLat;
        this.myForm.longitude = this.currentLong;
        this.myForm.location = this.address;
        this.myForm.deviceType = this.myForm.deviceToken = 'Web'
        this.userService.socialLogin(this.myForm).subscribe(res => {
          if (res.responseCode == 200) {
            this.loading = this.showError = false;
            this.storage.saveLoginUserDetails(res.userDetails);
            this.toastr.success(res.successMessage);
            this.router.navigate(['/dashboard']);
          } else {
            this.toastr.error(res.successMessage);
            this.loading = false;
          }
        }, (err) => {
          console.log(err);
        });
      }
    );
  }






}
