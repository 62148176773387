import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { CategoryService } from '../../apiServices/category/category.service';
import { DareService } from '../../../apiServices/dare/dare.service';
import { ChallengeService } from '../../../apiServices/challenge/challenge.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../helper/localStorage.service';

@Component({
  selector: 'app-view-challenge',
  templateUrl: './view-challenge.component.html',
  styleUrls: ['./view-challenge.component.scss']
})
export class ViewChallengeComponent implements OnInit {
  myForm: any = {};
  memberDetails: any = {};
  imageUrl: any = 'http://www.omnisttechhubsolutions.com/doyoudare/trunk/categoryimage/';
  loading: boolean = false;
  showError: boolean = false;
  challengeDetails: any = [];
  challengeDetailsWithMember: any = [];
  status: any = '1';
  memberType: any = '1';
  isEdit: any;
  paramsValue: any;
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  tags: any = [];
  shareUrl: any = '';
  constructor(
    private challengeService: ChallengeService,
    private dareService: DareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.checkisEditStatus();
    this.challengeDetails = JSON.parse(localStorage.getItem('challenge'));
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    this.dareService.getDaresForUploadProgress(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.challengeDetails = res.details;
        this.tags = (res.details.tag_list).split(",")
      }
    }, (err) => {
      console.log(err);
    });
    // var reqObj = {
    //   "user_id": this.authToken.user_id,
    //   "api_key": this.authToken.api_key,
    //   "play_id": this.paramsValue._value.pid,
    //   "category_id": this.challengeDetails.category_id,
    //   "page_no": "1",
    //   "page_size": "1"
    // }
    // this.challengeService.getDareDetailsUsingPlayId(reqObj).subscribe(res => {
    // this.challengeService.challengeDetails(reqObj).subscribe(res => {
    //   if (res.responseCode == 200) {
    //     this.loading = this.showError = false;
    //     this.challengeDetailsWithMember = res.details;
    //     // this.challengeDetails = JSON.parse(localStorage.getItem('dare'));
    //   } else {
    //     this.toastr.error(res.successMessage);
    //     this.loading = false;
    //   }
    // }, (err) => {
    //   console.log(err);
    // });
  }

  checkisEditStatus() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    this.dareService.getDareDetailsUsingPlayId(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.isEdit = res.status;
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }
  showDetails(data) {
    console.log('check---', data)
    this.memberDetails = data;
  }
  shareOption(data) {
    console.log('data----', data)
    this.shareUrl = window.location.origin + '/view_challenge_details/' + data.play_id;
    console.log('this.shareUrl====', this.shareUrl)
  }
}
