import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  backendUrl: any = environment.backendUrl;
  constructor(
    private http: HttpClient
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  };

  checkUserName(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getUserNameRequest`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }
  registration(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/registration`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }


  login(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/login`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  socialLogin(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/socialLogin`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  resendOtp(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/resendOtp`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  otpVerify(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/otpVerify`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  getProfile(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getUserProfile`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  addBankAccountDetail(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/addBankAccount`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  deleteBankAccountDetail(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/deleteBankAccount`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  editBankAccountDetail(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/editBankAccount`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getBankAccountDetail(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/bankList`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  changeNotificationSetting(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/userUpdateSetting`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  updateProfile(data): Observable<any> {
    var fd = new FormData();
    fd.append('user_id', data.user_id);
    fd.append('email', data.email);
    fd.append('gender', data.gender);
    fd.append('user_name', data.user_name);
    fd.append('about_me', data.about_me);
    fd.append('dob', data.dob);
    fd.append('mobile', data.mobile);
    fd.append('profile_pic', data.profile_pic);
    fd.append('qualification', data.qualification);
    return this.http.post(`${this.backendUrl}/userEditProfile`, fd, {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getQualifications(): Observable<any> {
    return this.http.get(`${this.backendUrl}/getQualificationList`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  getAboutUS(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/staticPages`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  dareOftheWeekList(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/weeklyDashboard`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  earningDsahboard(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/coinWalletList`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  getMembers(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getmemberlist`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  writeToUs(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/addWriteus`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  changePassword(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/changePassword`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  resetPassword(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/resetPassword`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  forgotPassword(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/forgotPassword`, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  getMobileAPKLink(): Observable<any> {
    return this.http.get(`${this.backendUrl}/getApkUrl`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }

  getEmailVerify(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/getEmailVerify`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }

  emaillinkVerify(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/emaillinkVerify`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Auth-Key": data.api_key
      })
    })
      .pipe(map(this.extractData));
  }
  sendSms(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/sendOtpMessge`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      })
    })
      .pipe(map(this.extractData));
  }
}
