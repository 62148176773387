export enum Event {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    INIT = 'init',                  // emit
    GET_ROOMS = 'get_rooms',             // emit
    ROOMS = 'rooms',			        // on
    GET_CHATS = 'get_chats',             // emit
    CHATS = 'chats',                 // on
    CREATE_GROUP = 'create_group',          // emit
    NEW_GROUP = 'new_group',             // on
    UPDATED_GROUP = 'updated_group',         // on
    ADD_PARTICIPANTS = 'add_participants',       // emit
    EXIT_GROUP = 'exit_group',            // emit
    ERROR = 'Error',                 // on
    SEND_MESSAGE = 'sendMessage',           // emit
    MESSAGE_SAVE = 'messageSave',           // on
    IMAGE_MESSAGE_SAVE = 'imageMessageSave',      // on
    RECEIVE_MESSAGE = 'receiveMessage',        // on
    DELIVERED_MESSAGE = 'deliveredMessage',      // on
    BLOCKED = 'blocked',               // on
    IS_ONLINE = 'isOnline',              // emit
    IS_ONLINE_STATUS = 'isOnlineStatus',        // on
    READ_MESSAGE = 'readMessage',           // emit
    MESSAGE_READ = 'messageRead',           // on   
    UN_DELIVER_MESSAGES_SENT = 'UnDeliverMessagesSent', // on
    MESSAGE_DELIVERED = 'messageDelivered'       // on

}