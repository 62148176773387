import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DareService } from '../../apiServices/dare/dare.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../helper/localStorage.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-view-claim',
  templateUrl: './view-claim.component.html',
  styleUrls: ['./view-claim.component.scss']
})
export class ViewClaimComponent implements OnInit {
  claimList: any = [];
  dareDetails: any;
  cliamDetails: any = {};
  status: any;
  myForm: any = {};
  loading: boolean = false;
  isCheck: boolean = false;
  showError: boolean = false;
  submitted: boolean = false;
  apiCalled: boolean = false;
  pagination: any = {};
  authToken: any = JSON.parse(localStorage.getItem('l_u_d'));
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'Are you sure, you want to delete this notification?';
  popoverInactivateMessage: string = 'Are you sure, you want to deactivate this user?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  date_now: any;
  date_future: any;
  page_no: any = '1';
  page_size: any = '10';
  paramsValue: any;
  isZoomImage: boolean = false;
  imageUrl: any;
  constructor(
    private dareService: DareService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private storage: LocalStorageService,
    public ngxSmartModalService: NgxSmartModalService,
  ) {
    this.paramsValue = this.route.params;
  }

  ngOnInit() {
    this.apiCalled = false;
    this.getClainDetails();
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    this.dareService.claimList(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.claimList = (res && res.categoryList) ? res.categoryList : [];
        this.apiCalled = true;
        // this.pagination = res.pagination;
        // console.log('claimList--', this.claimList)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }



  gettimeDiff(createAt, timestamp) {
    this.date_future = new Date(timestamp);
    this.date_now = new Date(createAt);
    var seconds = Math.floor((this.date_future - (this.date_now)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    return days > 0 ? `${days} days` : hours > 0 ? `${hours} hours` : minutes > 0 ? `${minutes} minutes` : seconds > 0 ? `${seconds} seconds` : '';
  }

  getClainDetails() {
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid
    }
    this.dareService.claimDares(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        // this.isEdit = res.status;
        this.dareDetails = res.claim_detail;
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  openPopUp(claim, status) {
    this.isZoomImage = false;
    if (claim.vote_staus == 2) {
      this.toastr.success('You have already send.')
      return;
    }
    this.ngxSmartModalService.open('myModal');
    this.cliamDetails = claim;
    this.status = status;
  }
  openImage(claim) {
    if (claim) {
      this.isZoomImage = true;
      this.imageUrl = claim.claim_image
      this.ngxSmartModalService.open('imageModal');
    } else {
      this.ngxSmartModalService.close('imageModal');
      this.isZoomImage = false;
    }
  }
  closeModal() {
    if (this.myForm.description) {
      this.ngxSmartModalService.close('myModal');
      this.submitVote(this.cliamDetails, this.status);
    } else {
      this.toastr.warning('Please fill reason first.');
    }
  }

  submitVote(claim, status) {
    // console.log('claim====', claim)
    // if(claim.vote_status=='1' || claim.vote_status=='0'||claim.vote_status=='2'){}
    var reqObj = {
      "user_id": this.authToken.user_id,
      "api_key": this.authToken.api_key,
      "play_id": this.paramsValue._value.pid,
      "group_id": claim.group_id,
      "claim_id": claim.id,
      "status": status,
      "description": (status == 2) ? this.myForm.description : ''
    }
    this.dareService.changeClaimStatus(reqObj).subscribe(res => {
      if (res.responseCode == 200) {
        this.loading = this.showError = false;
        this.toastr.success(res.successMessage);
        this.ngOnInit();
        // if (claim.play_type == '1') {
        //   this.router.navigate(['/dare/' + this.dareDetails.play_category_id + '/mydare']);
        // } else {
        //   this.router.navigate(['/' + this.dareDetails.play_category_id + '/challenges']);
        // }
        // this.claimList = (res && res.categoryList) ? res.categoryList : [];
        // // this.pagination = res.pagination;
        // console.log('claimList--', this.claimList)
      } else if (res.responseCode == 402) {
        this.toastr.error('Your session has expired, Please login again.');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res.successMessage);
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
